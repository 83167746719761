import React, { useEffect, useState, useReducer, useCallback } from 'react';
import querystring from 'query-string';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Box } from '@material-ui/core';

import { updateQuery } from '../../util/utilFunctions';
import nasmApi from '../../api/endpoints';
import { SAVE_STATES, UPLOAD_STATES } from '../../constants';

// Local
import PanelContainer from '../../components/Panels/PanelContainer';
import WorkoutSectionExerciseContext from '../../components/WorkoutSectionExerciseContext';
import ExerciseDetailsPanel from '../../components/Panels/ExerciseDetailsPanel';
import WorkoutListPanel from '../../components/Panels/WorkoutListPanel';
import EditExercisePanel from '../../components/Panels/EditExercisePanel';

import { PageHeader, PageToolBar } from '../../components';

import { useWorkoutsContext } from '../../contexts/WorkoutsContext';
import { clearProgram } from '../../reducers/selectedProgramReducer';
import { createNewWorkout, clearWorkout, selectWorkout } from '../../reducers/selectedWorkoutReducer';
import { programContexts, programLibrary } from '../../reducers/programContextReducer';
import { colors } from '../../styles';

import {
  initReducer,
  WORKOUT_BUTTONS_VISIBILITY_ACTIONS,
  workoutButtonsVisibilityReducer,
} from '../../reducers/local/workoutButtonsVisibilityReducer';

import { FloatingCircleButton } from '../../components/Buttons';
import AddWorkoutMenu from '../../components/Workouts/AddWorkoutMenu';
import WorkoutVideoPanelWrapper from '../../components/Workouts/WorkoutVideoPanelWrapper';
import ConfirmDialogPrompt from '../../components/Dialogs/ConfirmDialogPrompt';
import AddSuperSetsPanel from '../../components/Panels/AddSuperSetsPanel';
import AddCircuitsPanel from '../../components/Panels/AddCircuitsPanel';
import NoticeBar from '../../components/Notice/NoticeBar';

// Constants
const { LIBRARY: LIBRARY_CONTEXT, SCHEDULING: SCHEDULE_CONTEXT } = programContexts;


const views = {
  workoutList: {
    key: 'workoutList',
    workoutList: true,
  },
  workoutDetails: {
    key: 'workoutDetails',
    workoutList: true,
    workoutDetails: true,
  },
  editExercise: {
    key: 'editExercise',
    workoutDetails: true,
    editExercise: true,
  },
  addExercises: {
    key: 'addExercises',
    workoutDetails: true,
    addExercises: true,
  },
  viewExercise: {
    key: 'viewExercise',
    addExercises: true,
    viewExercise: true,
    // can be viewed together with viewExercise
    // when selecting an exercise from an NASM Workout
    workoutDetails: false,
  },
  viewNasmExercise: {
    key: 'viewNasmExercise',
    workoutDetails: true,
    viewNasmExercise: true,
  },
  videoWorkout: {
    key: 'videoWorkout',
    workoutList: true,
    videoWorkout: true,
  },
  superSetDetails: {
    key: 'superSetDetails',
    workoutDetails: true,
    superSetDetails: true,
  },
  superSetExerciseDetails: {
    key: 'superSetExerciseDetails',
    superSetDetails: true,
    viewExercise: true,
    workoutList: false,
  },
  circuitDetails: {
    key: 'circuitDetails',
    workoutDetails: true,
    circuitDetails: true,
  },
  circuitExerciseDetails: {
    key: 'circuitExerciseDetails',
    circuitDetails: true,
    viewExercise: true,
    workoutList: false,
  },
  progressionRegressionExDetails: {
    key: 'progressionRegressionExDetails',
    progressionRegressionExDetails: true,
    viewExercise: true,
    workoutDetails: true,
  },
};

function Workouts(props) {
  const userType = JSON.parse(localStorage.getItem("AUTH_TOKEN"));
  const history = useHistory();
  const location = history.location;
  const currentTab = location.pathname.split('/').pop();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const { sectionIds = [] } = props;

  const { removeWorkout, refreshWorkouts } = useWorkoutsContext();

  const [selectedWorkoutIndex, setSelectedWorkoutIndex] = useState(-1);
  const resetWorkoutIndex = () => { setSelectedWorkoutIndex(-1); };

  const [selectedExerciseDraggableId, setSelectedExerciseDraggableId] = useState('none');
  const resetSelectedExercise = () => setSelectedExerciseDraggableId('none');

  const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const view = query?.view || 'workoutList';

  const [workoutButtonsVisibilityFlags, localDispatch] =
    useReducer(workoutButtonsVisibilityReducer, {}, initReducer);

  const [openWorkoutMenu, setOpenWorkoutMenu] = useState(false);
  const [workoutMenuAnchorEl, setWorkoutMenuAnchorEl] = useState(undefined);

  const [uploadState, setUploadState] = useState(UPLOAD_STATES.Empty);
  const [saveState, setSaveState] = useState(SAVE_STATES.SAVED);
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
  const [showLeaveConfirmDialog, setLeaveConfirmDialog] = useState(false);
  const [confirmDiscardChanges, setConfirmDiscardChanges] = useState(false);
  const [pendingLocationPathName, setPendingLocationPathName] = useState(history.location.pathname);
  const [pendingSearchParams, setPendingSearchParams] = useState(history.location.search || '');

  const [pendingWorkout, setPendingWorkout] = useState({});
  const [pendingWorkoutIndex, setPendingWorkoutIndex] = useState(-1);
  const [uploadedMediaId, setUploadedMediaId] = useState('');
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});

  const programContext = useSelector(state => state.programContext.context);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const onChangeWorkoutPending = (workout, index) => {
    setPendingWorkout(workout);
    setPendingWorkoutIndex(index);
    // Reset save button state when choosing a new workout
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const onChangeWorkoutSection = (workout, index) => {
    if (programContext === LIBRARY_CONTEXT) {
      updateButtonVisibilityFlags({
        isCopyButtonVisible: location.pathname.includes('nasm-workouts'),
        isSaveButtonVisible: false,
        isAddExercisesButtonVisible: location.pathname.includes('my-workouts'),
        isAddButtonVisible: true,
      });
    } else if (programContext === SCHEDULE_CONTEXT) {
      updateButtonVisibilityFlags({
        isCopyButtonVisible: false,
        isSaveButtonVisible: false,
        isAddExercisesButtonVisible: false,
        isAddButtonVisible: false,
        isScheduleButtonVisible: true,
      });
    }

    onChangeWorkout(workout, index);
  };

  const onChangeWorkoutVideo = (workout, index) => {
    onChangeWorkout(workout, index);
  };

  const onChangeWorkout = (workout, index) => {
    setSelectedWorkoutIndex(index);

    // reset pending workout and workout index
    setPendingWorkout({});
    setSelectedWorkoutIndex(-1);
  };

  const onConfirmCloseVideoWorkout = () => {
    setConfirmDiscardChanges(true);
    setLeaveConfirmDialog(false);
    setUploadedMediaId('');
    resetWorkoutIndex();
  };

  const onCloseLeaveDialog = () => {
    setLeaveConfirmDialog(false);
  };

  const onHandleBlockedNavigation = (location, action) => {
    if (view === views.videoWorkout.key &&
      [UPLOAD_STATES.Uploading, UPLOAD_STATES.Processing].includes(uploadState) &&
      [SAVE_STATES.CLICKABLE, SAVE_STATES.LOADING].includes(saveState)) {

      // enable navigation
      if (confirmDiscardChanges) {
        setLeaveConfirmDialog(false);
        setUploadedMediaId('');

        const upcomingSearchParams = querystring.parse(pendingSearchParams);
        if (upcomingSearchParams.view === views.videoWorkout.key) {
          onChangeWorkoutVideo(pendingWorkout, pendingWorkoutIndex);
        } else if (upcomingSearchParams.view === views.workoutDetails.key) {
          onChangeWorkoutSection(pendingWorkout, pendingWorkoutIndex);
        }

        return true;
      }

      // disable navigation ~ display custom modal
      // a request to navigate to another area in the web app will be stopped
      // and need to be re-requested on confirmation
      setPendingLocationPathName(location.pathname);
      setPendingSearchParams(location.search);
      setLeaveConfirmDialog(true);
      return false;
    }

    // enable navigation
    setUploadedMediaId('');
    return true;
  };

  const cancelMediaUploadRequest = useCallback(() => {
    cancelToken.cancel('Uploading Custom Video Workout cancelled');
    setCancelToken(axios.CancelToken.source());
  }, [cancelToken]);

  function updateButtonVisibilityFlags(
    {
      isCopyButtonVisible,
      isSaveButtonVisible,
      isAddExercisesButtonVisible,
      isAddButtonVisible,
    }) {
    localDispatch({
      type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS,
      payload: {
        showCopyButton: isCopyButtonVisible,
        showSaveButton: isSaveButtonVisible,
        showAddExercisesButton: isAddExercisesButtonVisible,
        showAddButton: isAddButtonVisible,
      },
    });
  }

  // NAVIGATOR HELPER FUNCTIONS //

  const getWorkoutUrlParams = (workoutId, isVideoWorkout) => {
    const view = isVideoWorkout ? views.videoWorkout.key : views.workoutDetails.key;
    const query = updateQuery(location.search, { view, workoutId: workoutId });
    return {
      pathname: location.pathname,
      search: query,
    };
  };

  const addExercisesListLink = () => {
    return updateQuery(location.search, { view: views.addExercises.key, exerciseId: undefined });
  };

  const workoutListLink = () => {
    return { search: updateQuery(location.search, { view: views.workoutList.key, workoutId: undefined }) };
  };

  const copyWorkoutLink = () => {
    const query = updateQuery(location.search, { view: views.workoutDetails.key, workoutId: undefined });
    return `${location.pathname}?${query}`;
  };

  const editExerciseLink = () => {
    return updateQuery(location.search, { view: views.editExercise.key });
  };

  const viewExerciseLink = (exerciseId, toggleWorkoutDetailsVisibility = false) => {
    // toggle secondary panel on the right-hand side to view
    views.viewExercise.workoutDetails = toggleWorkoutDetailsVisibility;
    views.viewExercise.addExercises = !toggleWorkoutDetailsVisibility;

    return updateQuery(location.search, {
      view: views.viewExercise.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: true,
    });
  };

  const viewWorkoutDetailsLink = () => {
    return updateQuery(location.search, { view: views.workoutDetails.key, exerciseId: undefined });
  };

  const viewSuperSetsLink = () => {
    if(views[view].addExercises){
      views.superSetDetails.workoutDetails = false;
      views.superSetDetails.addExercises = true;
    } else {
      views.superSetDetails.workoutDetails = true;
      views.superSetDetails.addExercises = false;
    }
    return updateQuery(location.search, { view: views.superSetDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      exerciseId: exerciseId,
      view: views.superSetExerciseDetails.key,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewCircuitLink = () => {
    if(views[view].addExercises){
      views.circuitDetails.workoutDetails = false;
      views.circuitDetails.addExercises = true;
    }  else {
      views.circuitDetails.workoutDetails = true;
      views.circuitDetails.addExercises = false;
    }
    return updateQuery(location.search, { view: views.circuitDetails.key });
  };

  const viewCircuitExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      exerciseId: exerciseId,
      view: views.circuitExerciseDetails.key,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewProgressionRegressionExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    if(currentTab !== 'nasm-workouts' && views[view].viewExercise){
      views.progressionRegressionExDetails.addExercises = true;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else {
      views.progressionRegressionExDetails.workoutDetails = true;
      views.progressionRegressionExDetails.addExercises = false;
      views.progressionRegressionExDetails.editExercises = false;
    }
    return updateQuery(location.search, {
      view: views.progressionRegressionExDetails.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const navigateToMyWorkoutList = () => {
    history.push({
      pathname: '/libraries/workouts/my-workouts',
      search: '?view=workoutList',
    });
  };

  // END NAVIGATOR HELPER FUNCTIONS //

  useEffect(() => {
    dispatch(programLibrary());
  }, [dispatch]);

  useEffect(() => {
    // Reset highlighted exercises and workouts when
    // switching between My/NASM Workout tabs
    resetSelectedExercise();
    resetWorkoutIndex();
  }, [pathName]);

  // When Navigating from Programs to Workouts, ensure no programs are selected in redux
  // since those states prevent trainers from saving their workouts successfully
  useEffect(() => {
    dispatch(clearProgram());
  }, [dispatch]);

  useEffect(() => {
    if (pathName.includes('my-workouts')) {
      localDispatch({
        type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.RESET_FLAGS,
      });
    }

    if (pathName.includes('nasm-workouts')) {
      // On refresh if trainer is on nasm-workouts, make sure the copy button is only visible
      updateButtonVisibilityFlags({
        isCopyButtonVisible: true,
        isSaveButtonVisible: false,
        isAddExercisesButtonVisible: false,
        isAddButtonVisible: false,
      });

      // Only show addExercises when save button is visible or when in my-workouts
      views.viewExercise.workoutDetails = true;
      views.viewExercise.addExercises = false;
      dispatch(clearWorkout());

      // if Workout Details Panel is visible, on refresh, only have workout list and workout details panel visible
      // if trainer was looking at other panels such as Add Exercises Panel, that panel will be closed on refresh
      // to prevent from being in an invalid state where bugs such as dragging an exercise into workout details panel
      // without selecting the copy button first will cause the workout to not contain the exercise recently added in
      const query = querystring.parse(location.search || {}, { parseBooleans: true });
      if (query.workoutId) {
        dispatch(selectWorkout({ id: query.workoutId }));
        // reset view to workout-details on refresh
        const newQuery = updateQuery(location.search, { view: views.workoutDetails.key });
        history.replace({ pathname: pathName, search: newQuery });
      } else {
        history.replace({ pathname: pathName, search: `?view=${views.workoutList.key}` });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  // Refresh Protection
  useEffect(() => {
    // See: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    function onUnload(e) {
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }

    const isUploadInProgress = [UPLOAD_STATES.Processing, UPLOAD_STATES.Uploading].includes(uploadState);
    const areUnsavedChanges = [SAVE_STATES.CLICKABLE, SAVE_STATES.LOADING].includes(saveState);
    if (view === 'videoWorkout' && isUploadInProgress && areUnsavedChanges) {
      window.addEventListener('beforeunload', onUnload);
    }

    return () => {
      if (view === 'videoWorkout' && isUploadInProgress && areUnsavedChanges) {
        window.removeEventListener('beforeunload', onUnload);
      }
    };
  }, [view, saveState, uploadState]);

  // Make another request to react-router-dom to navigate to intended location
  // after user confirms the custom dialog
  useEffect(() => {
    if (confirmDiscardChanges) {
      setConfirmDiscardChanges(false);
      cancelMediaUploadRequest();

      history.push({
        pathname: pendingLocationPathName,
        search: pendingSearchParams,
      });
    }
  }, [
    confirmDiscardChanges,
    cancelMediaUploadRequest,
    pendingLocationPathName,
    pendingSearchParams,
    history,
  ]);

  const onCreateWorkout = () => {
    // clear selected workout and program to prevent
    // unintended side effects that occur in reducer functions such as saveWorkout()
    dispatch(clearWorkout());
    dispatch(clearProgram());
    dispatch(createNewWorkout());
  };

  const onCreateWorkoutAndUpdateNavigation = () => {
    history.push({
      pathname: '/libraries/workouts/my-workouts',
      search: '?view=workoutDetails',
    });
    onCreateWorkout();
  };

  // This function is elevated here so that list items
  // and the video workout panel have access
  const onDeleteWorkout = (workout) => {
    const { name, id } = workout;
    nasmApi.nasmWorkouts.deleteWorkout(id)
      .then(() => {
        // if the workout being viewed in the workout details panel will be deleted
        // close the panel and only show the workout list panel
        const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
        if (location.pathname.includes('libraries/workouts') && query.view !== 'workoutList') {
          navigateToMyWorkoutList();
        }

        dispatch(clearWorkout());
        setSelectedWorkoutIndex(-1);

        // Refresh workout list to exclude deleted workout
        removeWorkout(id);
      })
      .catch(e => {
        window.alert(`Failed to delete ${name ?? 'this workout'} with error:\n
        ${JSON.stringify(e, null, 2)}`);
      });
  };

  return (
    <>
      <PageHeader title="Workouts" />
      <PageToolBar
        linkOptions={[
          {
            label: "My Workouts",
            to: "/libraries/workouts/my-workouts",
          },
          {
            label: "NASM Workouts",
            to: "/libraries/workouts/nasm-workouts",
          },
        ]}
        color={selectedProfile?.ClubId ? colors.graphite : colors.lightBlue}
      />
      <FloatingCircleButton
        disabled={view !== views.workoutList.key || openWorkoutMenu}
        onClick={(e) => {
          setWorkoutMenuAnchorEl(e.target);
          setOpenWorkoutMenu(true);
        }}
      />
      <AddWorkoutMenu
        open={openWorkoutMenu}
        anchorEl={workoutMenuAnchorEl}
        onCreateWorkout={() => {
          onCreateWorkoutAndUpdateNavigation();
          setOpenWorkoutMenu(false);
          setWorkoutMenuAnchorEl(undefined);
        }}
        onCreateWorkoutVideo={() => {
          history.push({
            pathname: "/libraries/workouts/my-workouts",
            search: "?view=videoWorkout&workoutId=new",
          });

          setOpenWorkoutMenu(false);
          setWorkoutMenuAnchorEl(undefined);

          dispatch(clearWorkout());
        }}
        onCloseMenu={() => {
          setOpenWorkoutMenu(false);
          setWorkoutMenuAnchorEl(undefined);
        }}
      />
      <Box>
        {userType?.cc_manager && (
          <Box style={{ padding: "20px 90px 0px 90px" }}>
            <NoticeBar />
          </Box>
        )}
        <PanelContainer>
          <WorkoutListPanel
            visible={!!views[view].workoutList}
            getWorkoutUrlParams={getWorkoutUrlParams}
            copyWorkoutLink={copyWorkoutLink}
            selectedWorkoutIndex={selectedWorkoutIndex}
            setSelectedWorkoutIndex={setSelectedWorkoutIndex}
            pathName={pathName}
            updateButtonVisibilityFlags={updateButtonVisibilityFlags}
            onCreateWorkout={onCreateWorkout}
            ftuTo="/libraries/workouts/my-workouts/?view=workoutDetails&workoutId=new"
            ftuBtnHidden={!!views[view].workoutDetails}
            onDeleteWorkout={onDeleteWorkout}
            onChangeWorkoutPending={onChangeWorkoutPending}
          />
          <Switch>
            {/* My Workouts */}
            <Route path="/libraries/workouts/my-workouts">
              <WorkoutSectionExerciseContext
                isWorkoutSectionsVisible={!!views[view].workoutDetails}
                isAddExercisesVisible={!!views[view].addExercises}
                sectionIds={sectionIds}
                // Navigation
                currentView={view}
                addExercisesKey={views.addExercises.key}
                addExercisesListLink={addExercisesListLink}
                backLink={workoutListLink}
                copyWorkoutLink={copyWorkoutLink}
                editExerciseLink={editExerciseLink}
                viewWorkoutDetailsLink={viewWorkoutDetailsLink}
                viewExerciseLink={viewExerciseLink}
                resetWorkoutIndex={resetWorkoutIndex}
                selectedExerciseDraggableId={selectedExerciseDraggableId}
                setSelectedExerciseDraggableId={setSelectedExerciseDraggableId}
                resetSelectedExercise={resetSelectedExercise}
                workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
                updateButtonVisibilityFlags={updateButtonVisibilityFlags}
                refreshWorkouts={refreshWorkouts}
                viewSuperSetsLink={viewSuperSetsLink}
                setSuperSetData={setSuperSetData}
                viewCircuitLink={viewCircuitLink}
                setCircuitsData={setCircuitsData}
              />
              <AddSuperSetsPanel
                visible={!!views[view].superSetDetails}
                resetSelection={resetSelectedExercise}
                backLink={
                  views[view].addExercises
                    ? addExercisesListLink
                    : viewWorkoutDetailsLink
                }
                superSetData={superSetData}
                setSuperSetData={setSuperSetData}
                viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
                isAutoSaveEnabled
                isDeletable
              />
              <AddCircuitsPanel
                visible={!!views[view].circuitDetails}
                resetSelection={resetSelectedExercise}
                backLink={
                  views[view].addExercises
                    ? addExercisesListLink
                    : viewWorkoutDetailsLink
                }
                circuitData={circuitData}
                setCircuitsData={setCircuitsData}
                viewCircuitExDetailsLink={viewCircuitExDetailsLink}
                isAutoSaveEnabled
                isDeletable
              />
              <EditExercisePanel
                backLink={viewWorkoutDetailsLink}
                visible={!!views[view].editExercise}
                isAutoSaveEnabled={
                  workoutButtonsVisibilityFlags.isAutoSaveEnabled
                }
                resetExerciseIndex={resetSelectedExercise}
                viewExerciseDetailsLink={viewProgressionRegressionExDetailsLink}
              />
              <ExerciseDetailsPanel
                visible={!!views[view].viewExercise}
                isAutoSaveEnabled={
                  workoutButtonsVisibilityFlags.isAutoSaveEnabled
                }
                backLink={
                  views[view].superSetDetails
                    ? viewSuperSetsLink
                    : views[view].circuitDetails
                    ? viewCircuitLink
                    : views[view].progressionRegressionExDetails
                    ? viewWorkoutDetailsLink
                    : addExercisesListLink
                }
                resetSelection={resetSelectedExercise}
                hideAddBtn={
                  views[view].superSetDetails ||
                  views[view].circuitDetails ||
                  views[view].progressionRegressionExDetails
                    ? true
                    : false
                }
                viewExerciseDetailsLink={
                  views[view].superSetDetails
                    ? viewSuperSetExDetailsLink
                    : views[view].circuitDetails
                    ? viewCircuitExDetailsLink
                    : viewProgressionRegressionExDetailsLink
                }
              />
              <WorkoutVideoPanelWrapper
                visible={!!views[view].videoWorkout}
                currentView={view}
                cancelToken={cancelToken}
                saveState={saveState}
                setSaveState={setSaveState}
                uploadState={uploadState}
                setUploadState={setUploadState}
                cancelMediaUploadRequest={cancelMediaUploadRequest}
                onDeleteWorkout={onDeleteWorkout}
                resetWorkoutIndex={resetWorkoutIndex}
                navigateToMyWorkoutList={navigateToMyWorkoutList}
                uploadedMediaId={uploadedMediaId}
                setUploadedMediaId={setUploadedMediaId}
              />
            </Route>
            {/* NASM Workouts */}
            <Route path="/libraries/workouts/nasm-workouts">
              <WorkoutSectionExerciseContext
                isWorkoutSectionsVisible={!!views[view].workoutDetails}
                isAddExercisesVisible={!!views[view].addExercises}
                sectionIds={sectionIds}
                // Navigation
                currentView={view}
                addExercisesKey={views.addExercises.key}
                addExercisesListLink={addExercisesListLink}
                backLink={workoutListLink}
                copyWorkoutLink={copyWorkoutLink}
                editExerciseLink={editExerciseLink}
                viewWorkoutDetailsLink={viewWorkoutDetailsLink}
                viewExerciseLink={viewExerciseLink}
                resetWorkoutIndex={resetWorkoutIndex}
                selectedExerciseDraggableId={selectedExerciseDraggableId}
                setSelectedExerciseDraggableId={setSelectedExerciseDraggableId}
                resetSelectedExercise={resetSelectedExercise}
                workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
                updateButtonVisibilityFlags={updateButtonVisibilityFlags}
              />
              <EditExercisePanel
                backLink={viewWorkoutDetailsLink}
                visible={!!views[view].editExercise}
                resetSelectedExercise={resetSelectedExercise}
              />
              <ExerciseDetailsPanel
                visible={!!views[view].viewExercise}
                isAutoSaveEnabled={
                  workoutButtonsVisibilityFlags.isAutoSaveEnabled
                }
                backLink={
                  views.viewExercise.workoutDetails
                    ? viewWorkoutDetailsLink
                    : addExercisesListLink
                }
                resetSelection={resetSelectedExercise}
                viewExerciseDetailsLink={viewProgressionRegressionExDetailsLink}
              />
            </Route>
            {/* Default Path */}
            <Route exact path="/libraries/workouts">
              <Redirect to="/libraries/workouts/my-workouts" />
            </Route>
            {/* Fallback Path */}
            <Route path="/libraries/workouts/*">
              <Redirect to="/libraries/workouts/my-workouts" />
            </Route>
          </Switch>
        </PanelContainer>
      </Box>
      <ConfirmDialogPrompt
        open={showLeaveConfirmDialog}
        onHandleBlockedNavigation={onHandleBlockedNavigation}
        handleConfirmAction={onConfirmCloseVideoWorkout}
        onClose={onCloseLeaveDialog}
        title={"Wait! Upload in progress"}
        description={
          "Your video is currently uploading." +
          " If you navigate away from this page all progress will be lost."
        }
        actionButtonTitle={"Discard"}
        cancelButtonTitle={"Cancel"}
      />
    </>
  );
}

export default Workouts;
