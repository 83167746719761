import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, makeStyles } from '@material-ui/core/styles';
import { Typography, MenuItem, Box } from '@material-ui/core';
import { colors } from '../../styles';

import DashboardDialog from './DashboardDialog';
import { MacaroniButton } from '../Buttons';
import { StatelessDropdown as Dropdown } from '../Inputs';
import { addPossessiveApostrophe } from '../../util/utilFunctions';
import MilestoneDragDropContext from '../Milestones/MilestoneDragDropContext';
import CompletedMilestonesAccordion from '../Milestones/CompletedMilestonesAccordion';

const useMenuItemStyles = makeStyles({
  menuItem: {
    padding: '12px 40px',
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
  header: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '30px',
    color: colors.carbon_black,
  },
  subHeader: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
    lineHeight: '20px',
    color: colors.carbon_black,
  },
});

const ActivitySection = styled('div')({
  padding: '28px 33px 0 33px',
});

const Header = styled(Typography)({
  fontSize: '22px',
  lineHeight: '30px',
});

const DropdownSpacing = styled('div')({
  margin: '18px 0',
});

const MilestonesHeading = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 33px 12px 33px',
});

const activityLevelMenuItems = (optionsArray, classes) => {
  return optionsArray.map(([optionKey, optionLabel]) => {
    const { label, description } = optionLabel;
    return (
      <MenuItem
        className={classes.menuItem}
        key={optionKey}
        value={optionKey}
      >
        <Box>
          <Typography className={classes.header}>{label}</Typography>
          <Typography className={classes.subHeader}>{description}</Typography>
        </Box>
      </MenuItem>
    );
  });
};

function GoalsDialog (props) {
  const {
    open,
    onClose,
    client,
    focusOptions,
    activityLevelOptions,
    saveState,
    resetSaveState,
    onSaveGoals,
    loading,
    incompleteMilestones,
    completedMilestones,
    saveNewMilestone,
    saveEditedMilestone,
    deleteMilestone,
    reorderMilestones,
    createNewMilestone,
    isAdding,
    isDeleting,
    isFtu,
    markMilestoneAsComplete,
  } = props;

  const classes = useMenuItemStyles();
  const { cc_manager: isCCManager } = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
  const isReadOnly = isCCManager || false;

  const initialFocus = client.client_user?.goals[0]?.id ?? '';
  const initialActivityLevel = client.client_user?.activity_level_id ?? '';

  const [selectedFocus, setSelectedFocus] = useState(initialFocus);
  const [selectedActivityLevel, setSelectedActivityLevel] = useState(initialActivityLevel);

  const onChangeFocus = (e) => {
    setSelectedFocus(e.target.value);
    resetSaveState();
  };

  const onChangeActivityLevel = (e) => {
    setSelectedActivityLevel(e.target.value);
    resetSaveState();
  };

  const resetGoalInformationAndClose = () => {
    onClose();
    resetSaveState();
    setSelectedActivityLevel(initialActivityLevel);
    setSelectedFocus(initialFocus);
  };

  const onPressAdd = () => {
   createNewMilestone();
  };

  const onFinishedEditing = (milestone) => {
    if (!milestone.id) {
      saveNewMilestone(milestone);
    } else {
      saveEditedMilestone(milestone);
    }
  };

  return (
    <DashboardDialog
      open={open}
      onClose={resetGoalInformationAndClose}
      headerColor={colors.purple}
      title={`${addPossessiveApostrophe(client.first_name)} Goals`}
      saveState={saveState}
      onClickSave={() => onSaveGoals(selectedFocus, selectedActivityLevel)}
      isReadOnly={isReadOnly}
    >
      <Box>
        <ActivitySection>
          <Header>Activity</Header>
          <DropdownSpacing>
            <Dropdown
              label='Focus'
              options={focusOptions}
              currentValue={selectedFocus}
              onOptionChanged={onChangeFocus}
              width='100%'
              fontFamily='Roboto, sans-serif'
              fontSize={14}
            />
          </DropdownSpacing>
          <DropdownSpacing>
            <Dropdown
              label='Activity Level'
              options={activityLevelOptions}
              currentValue={selectedActivityLevel}
              onOptionChanged={onChangeActivityLevel}
              width='100%'
              fontFamily='Roboto, sans-serif'
              customMenuItems={activityLevelMenuItems}
              menuItemClasses={classes}
            />
          </DropdownSpacing>
        </ActivitySection>
        <Box>
          <MilestonesHeading>
            <Header>Milestones</Header>
            {!isReadOnly && (
              <MacaroniButton
                width="100px"
                active={isAdding}
                onClick={onPressAdd}
                disabled={isAdding}
              >
                Add Goal
              </MacaroniButton>
            )}
          </MilestonesHeading>
          <MilestoneDragDropContext
            milestones={incompleteMilestones}
            loading={loading}
            onFinishEditing={onFinishedEditing}
            isAdding={isAdding}
            isDeleting={isDeleting}
            deleteMilestone={deleteMilestone}
            reorderMilestones={reorderMilestones}
            markMilestoneAsComplete={markMilestoneAsComplete}
            isFtu={isFtu}
            isReadOnly={isReadOnly}
          />
          <CompletedMilestonesAccordion
            visible={completedMilestones.length > 0}
            completedMilestones={completedMilestones}
            markMilestoneAsComplete={markMilestoneAsComplete}
            isReadOnly={isReadOnly}
          />
        </Box>
      </Box>
    </DashboardDialog>
  );
}

export default GoalsDialog;

GoalsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  focusOptions: PropTypes.object,
  activityLevelOptions: PropTypes.object,
  selectedFocus: PropTypes.string,
  selectedActivityLevel: PropTypes.string,
  saveState: PropTypes.string,
  onChangeFocus: PropTypes.func,
  onChangeActivityLevel: PropTypes.func,
  onSaveGoals: PropTypes.func,
  resetSaveState: PropTypes.func,
  client: PropTypes.shape({
    first_name: PropTypes.string,
  }),
};
