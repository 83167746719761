import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';

import HighlightSearchBar from '../../components/Inputs/HighlightSearchBar';
import ClientIcon from '../../components/ClientDashboard/ClientIcon';

import { colors } from '../../styles';

import EmptySection from '../../components/EmptyStates/EmptySection';

import { MyClientsPageHeader, ChooseClientsPageHeader } from '../../components/PageHeaders';
import { FloatingCircleButton } from '../../components/Buttons';

import { getMe } from '../../reducers/currentUserReducer';
import AddNewClient from '../../components/Dialogs/AddNewClient';

import ftuClient from '../../resources/image-ftu-add-client.png';

import ClientListSkeleton from '../../components/LoadingStates/ClientListSkeleton';
import Panel from '../../components/Panels/Panel';

import { checkTrainerSubscriptionStatus, SUBSCRIPTION_STATUS } from '../../util/trainerSubscription';
import { checkForActiveFoundationPermission } from '../../util/trainerFoundationPermission';
import { useClientsContext } from '../../contexts/ClientsContext';
import UpgradePlanDialog from '../../components/Dialogs/UpgradePlanDialog';
import { deselectClient, selectClient } from '../../reducers/selectedClientReducer';
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog';
import { deselectGroup } from '../../reducers/selectedGroupReducer';
import { handleProfileClick } from '../../reducers/clubConnectReducer';
import NoticeBar from '../../components/Notice/NoticeBar';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    height: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topSection: {
    paddingTop: '30px',
    flex: 1,
  },
  listViewIconCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    border: '1px solid #ced3d7',
    borderRadius: '18px',
    cursor: 'pointer',
  },
  thumbnailViewIconCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    backgroundColor: 'rgba(206, 211, 215, 0.32)',
    borderRadius: '18px',
    cursor: 'pointer',
  },
  clientListCont: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',
    overflowY: 'auto',
  },
});

export default function MyClientsList (props) {
  const { selectedClient } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(SUBSCRIPTION_STATUS.PENDING);
  const [addNewClientOpen, setAddNewClientOpen] = useState(false);
  const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
  const [isFoundationPermissionActive, setIsFoundationPermissionActive] = useState(false);
  const [confirmActivateClientDialog, setConfirmActivateClientDialog] = useState(false);
  const [isPlusButtonDisabled, setPlusButtonDisabled] = useState(false);
  const userType = JSON.parse(localStorage.getItem("AUTH_TOKEN"));

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(state => state.currentUser);
  const trainerPermissions = useMemo(() => currentUser.permissions, [currentUser.permissions]);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const {
    loading,
    error,
    allClients,
    activeClients,
    filteredClients: clients,
    deactivateClients,
    activateClients,
    activateClient,
    findClients,
    fetchClients,
  } = useClientsContext();

  useEffect(() => {
    dispatch(deselectClient());
    dispatch(deselectGroup());
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser.club_connect_user) {
      const query = new URLSearchParams(window.location?.href?.split('?')[1]);
      const clubId = query.get('club_id');
      if (clubId) {
        dispatch(handleProfileClick(null, clubId));
      }
    }
  }, [dispatch, currentUser.club_connect_user]);

  useEffect(() => {
    fetchClients(selectedProfile?.ClubId, selectedProfile?.Locations?.Id);
  }, [fetchClients, selectedProfile]);

  useEffect(() => {
    const expirationDate = currentUser.subscription_expiration_date;
    const subStatus = checkTrainerSubscriptionStatus(expirationDate);

    Sentry.addBreadcrumb({
      category: 'TrainerSubscriptions',
      message: `Trainer email: ${currentUser.email} |` +
        `sub status: ${subStatus} |` +
        `expire date: ${expirationDate} |`,
      level: 'info',
    });

    Sentry.captureMessage('MyClientsList warning', Sentry.Severity.Warning);


    setSubscriptionStatus(subStatus);

    Sentry.addBreadcrumb({
      category: 'TrainerSubscriptions',
      message: `Trainer email: ${currentUser.email} |` +
        `sub status: ${subStatus} |` +
        `expire date: ${expirationDate} |`,
      level: 'info',
    });

    Sentry.captureMessage('MyClientsList warning', Sentry.Severity.Warning);

  }, [currentUser.subscription_expiration_date, currentUser.email]);

  useEffect(() => {
    // Determine if trainer has an active edge_trainer_foundation permission
    const result = checkForActiveFoundationPermission(trainerPermissions);
    setIsFoundationPermissionActive(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(trainerPermissions)]);

  // Handle activation/de-activation logic based on subscription status
  // and EDGE trainer foundation permission
  const clubId = selectedProfile?.ClubId || '';
  const locationsId =(selectedProfile && selectedProfile.Locations && selectedProfile.Locations.Id) ?
    selectedProfile.Locations.Id : '';
  useEffect(() => {
    if(subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBED ||
      !!currentUser.bypass_subscription ||
      isFoundationPermissionActive) {
      if(allClients.length !== activeClients.length) {
        const reqObj = {
          club_id: clubId,
          location_id: locationsId,
        };
        activateClients(reqObj);
      }
    } else if(!!currentUser.bypass_subscription === false &&
      [SUBSCRIPTION_STATUS.EXPIRED, SUBSCRIPTION_STATUS.NONE].includes(subscriptionStatus)) {
      if(activeClients.length > currentUser.max_free_clients) {
        deactivateClients();
      }
    }
  },[
    activateClients,
    deactivateClients,
    subscriptionStatus,
    activeClients.length,
    allClients.length,
    currentUser.bypass_subscription,
    currentUser.max_free_clients,
    isFoundationPermissionActive,
    clubId,
    locationsId,
  ]);

  // Search for clients by name
  useEffect(() => {
    findClients(searchText);
  }, [findClients, searchText]);

  const onClearSearch = () => {
    setSearchText('');
  };

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const onClickAdd = () => {
    setPlusButtonDisabled(true);

    const canAddClient = !!currentUser.bypass_subscription ||
      subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBED ||
      activeClients.length < currentUser.max_free_clients ||
      isFoundationPermissionActive;

    if (canAddClient || selectedProfile?.ClubId) {
      setAddNewClientOpen(true);
    } else {
      setShowUpgradePlanDialog(true);
    }
  };

  const onClickClient = (client) => {
    dispatch(selectClient(client ?? {}));
    const isActive = client.client_user.active_under_current_trainer;
    const canActivateClient = activeClients.length < currentUser.max_free_clients ||
      !!currentUser.bypass_subscription;
    if (isActive || selectedProfile?.ClubId) {
      history.push(`/clients/my-clients/${client.first_name.toLowerCase()}/dashboard`);
    } else {
      if (canActivateClient) {
        setConfirmActivateClientDialog(true);
      } else {
        setShowUpgradePlanDialog(true);
      }
    }
  };

  const onCloseAddClient = () => {
    setAddNewClientOpen(false);
    setPlusButtonDisabled(false);
  };

  const onCloseUpgradeDialog = () => {
    setShowUpgradePlanDialog(false);
    setPlusButtonDisabled(false);
  };

  const onViewUpgradeOptions = () => {
    setShowUpgradePlanDialog(false);
    history.replace('/clients/view-subscriptions');
  };

  const areClientsLoadedAndAdded = !loading && !error && allClients.length > 0;
  const isInitialLoading = loading && !error && allClients.length === 0;
  const areNoSearchResultsFound = !loading && !error && clients.length === 0 && searchText !== '';
  const areClientResultsFound = !loading && !error && clients.length > 0;
  const isFirstTimeUserExp = !loading && !error && allClients.length === 0;

  return (
    <>
      <ClientsPageHeader
        /* eslint-disable-next-line camelcase */
        maxFreeClients={currentUser?.max_free_clients ?? 0}
        activeClientsCount={activeClients.length}
        loading={loading}
        isFoundationPermissionActive={isFoundationPermissionActive}
        subscriptionStatus={subscriptionStatus}
        selectedProfile={selectedProfile}
      />
      <FloatingCircleButton
        disabled={isPlusButtonDisabled}
        onClick={onClickAdd}
      />
      <Box className={classes.container}>
        <Box className={classes.root}>
        <Container className={classes.topSection}>
          {userType?.cc_manager && <NoticeBar />}
            <ClientListSkeleton visible={isInitialLoading} />
            <SearchAndFiltersBar
              visible={areClientsLoadedAndAdded}
              searchText={searchText}
              onClearSearch={onClearSearch}
              onChangeSearchText={onChangeSearchText}
            />
            <ClientListContents
              visible={areClientResultsFound}
              clients={clients}
              onClickClient={onClickClient}
              selectedProfile={selectedProfile}
            />
            <NoSearchResults
              visible={areNoSearchResultsFound}
            />
            <EmptySectionPanel
              visible={isFirstTimeUserExp}
              pathname={history.location.pathname}
              onClickAdd={onClickAdd}
            />
          </Container>
        </Box>
        {/* Temporarily hide Footer Containers that contain cpt related image banners */}
        {/* <FooterContainer /> */}
      </Box>
      <AddNewClient
        open={addNewClientOpen}
        onClose={onCloseAddClient}
      />
      <UpgradePlanDialog
        open={showUpgradePlanDialog}
        onClose={onCloseUpgradeDialog}
        onViewUpgradeOptions={onViewUpgradeOptions}
      />
      <ConfirmDialog
        open={confirmActivateClientDialog}
        onClose={() => setConfirmActivateClientDialog(false)}
        handleConfirmAction={() => {
          const reqObj = {
            userId: selectedClient.id,
            club_id: selectedProfile?.ClubId,
            location_id: selectedProfile?.Locations?.Id,
          };
          activateClient(reqObj);
          setConfirmActivateClientDialog(false);
        }}
        title={`Activate ${selectedClient.first_name}`}
        description={`Choose ${selectedClient.first_name} as your one free client? This cannot be reversed.`}
        actionButtonTitle='Yes'
        cancelButtonTitle='No'
      />
    </>
  );
}

const useSearchAndFilterBarStyles = makeStyles({
  searchFilterCont: {
    display: 'flex',
    width: '430px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

/**
 * @return {JSX|null}
 */
function SearchAndFiltersBar (props) {
  const {
    visible,
    searchText,
    onClearSearch,
    onChangeSearchText,
  } = props;

  const classes = useSearchAndFilterBarStyles();

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.searchFilterCont}>
      <HighlightSearchBar
        isClearable={searchText.length > 0}
        onClickClear={onClearSearch}
        value={searchText}
        onChange={onChangeSearchText}
      />
      {/* Hiding Filter and grid/list view Icons temporarily */}
      {/* <OvalButton>Filter</OvalButton>
              <Box className={classes.listViewIconCont}>
                <img src={listIcon} alt='list-view' />
              </Box>
              <Box className={classes.thumbnailViewIconCont}>
                <img src={thumbnailIcon} alt='thumbnail-view' />
              </Box> */}
    </Box>
  );
}

const useClientListContentsStyle = makeStyles({
  clientListCont: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '20px',
    overflowY: 'auto',
  },
});

/**
 * @return {JSX|null}
 */
function ClientListContents (props) {
  const { visible, clients = [], onClickClient, selectedProfile } = props;
  const classes = useClientListContentsStyle();

  if(!visible) {
    return null;
  }

  return (
    <Box className={classes.clientListCont}>
      {clients.map((client, index) => {
        let isActive = !!client.client_user.active_under_current_trainer;
        if (selectedProfile?.ClubId) {
          isActive = true;
        }
        return (
          <ClientIcon
            client={client}
            key={index}
            isActive={isActive}
            onClick={() => onClickClient(client)}
          />
        );
      })}
    </Box>
  );
}

const useNoSearchResultsStyles = makeStyles({
  noSearchResults: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 20,
  },
});

/**
 * @return {JSX|null}
 */
function NoSearchResults (props) {
  const { visible } = props;
  const classes = useNoSearchResultsStyles();

  if (!visible) {
    return null;
  }

  return (
    <Container className={classes.noSearchResults}>
      <Typography>No Search Results Found.</Typography>
    </Container>
  );
}

/**
 * @return {JSX|null}
 */
function EmptySectionPanel (props) {
  const { visible, onClickAdd, pathname } = props;

  if (!visible) {
    return null;
  }

  return (
    <Panel>
      <EmptySection
        ftuImg={ftuClient}
        emptyTitle='Add your first Client'
        emptyDescription={'Train your clients by assigning programs, workouts, exercises,' +
        ' track their progress and more.'}
        onClickPlus={onClickAdd}
        to={pathname}
      />
    </Panel>
  );
}

function ClientsPageHeader(props) {
  const {
    maxFreeClients,
    activeClientsCount,
    loading,
    isFoundationPermissionActive,
    subscriptionStatus,
    selectedProfile,
  } = props;

  // prevents Choose Clients Page Header from rendering pre-maturely
  // if data from API has not completed loading
  if(loading) {
    return <MyClientsPageHeader />;
  }

  const isSubscriptionActive = [SUBSCRIPTION_STATUS.SUBSCRIBED, SUBSCRIPTION_STATUS.PENDING]
      .includes(subscriptionStatus);
  const remainingFreeClientsCount = maxFreeClients - activeClientsCount;

  const showChooseClientHeader = !selectedProfile?.ClubId
    && remainingFreeClientsCount > 0
    && !isFoundationPermissionActive
    && !isSubscriptionActive;

  if (showChooseClientHeader) {
    return <ChooseClientsPageHeader remainingFreeClientsCount={remainingFreeClientsCount}/>;
  }

  return <MyClientsPageHeader />;
}
