import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import querystring from 'query-string';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Box } from '@material-ui/core';

// Local
import PanelContainer from '../../components/Panels/PanelContainer';
import Panel from '../../components/Panels/Panel';
import ExercisesScreen from '../../components/Exercises/ExercisesScreen';
import ExerciseDetailsPanel from '../../components/Panels/ExerciseDetailsPanel';
import CustomExercisePanel from '../../components/Panels/CustomExercisePanel';
import { updateQuery } from '../../util/utilFunctions';
import { PageHeader, PageToolBar } from '../../components';
import {FloatingCircleButton} from '../../components/Buttons';
import { colors } from '../../styles';
import AddSuperSetsPanel from '../../components/Panels/AddSuperSetsPanel';
import AddCircuitsPanel from '../../components/Panels/AddCircuitsPanel';
import SlidingFlexTransition from '../../components/Transitions/SlidingFlexTransition';
import NoticeBar from '../../components/Notice/NoticeBar';

const UNSELECTED_INDEX = -1;

const views = {
  myExercises: {
    key: 'my-exercises',
    exerciseList: true,
  },
  exerciseList: {
    key: 'exerciseList',
    exerciseList: true,
  },
  exerciseDetails: {
    key: 'exerciseDetails',
    exerciseList: true,
    exerciseDetails: true,
  },
  customExerciseDetails: {
    key: 'customExerciseDetails',
    exerciseList: true,
    customExerciseDetails: true,
  },
  superSetDetails: {
    key: 'superSetDetails',
    exerciseList: true,
    superSetDetails: true,
  },
  circuitDetails: {
    key: 'circuitDetails',
    exerciseList: true,
    circuitDetails: true,
  },
  superSetExerciseDetails: {
    key: 'superSetExerciseDetails',
    superSetDetails: true,
    exerciseDetails: true,
    exerciseList: false,
  },
  circuitExerciseDetails: {
    key: 'circuitExerciseDetails',
    circuitDetails: true,
    exerciseDetails: true,
    exerciseList: false,
  },
};

const MY_EXERCISES_LINK_TO = {
  pathname: '/libraries/exercises/my-exercises',
  state: {
    myExercises: true,
  },
};

const NASM_EXERCISES_LINK_TO = {
  pathname: '/libraries/exercises/nasm-exercises',
  state: {
    myExercises: false,
  },
};

const useStyles = makeStyles(() => ({
  createNewTxt: {
    fontSize: "14px",
    padding: "10px",
    paddingLeft: "15px",
  },
  menu: {
    width: 230,
    paddingTop: 0,
    paddingBottom: 0,
  },
  popoverPaper: {
    boxShadow: "0 0px 3px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "6px",
    width: "200px",
    border: `1px solid ${colors.divider_line_white}`,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: colors.baby_blue,
    },
  },
  menuItemTxt: {
    fontSize: "17px",
  },
  divider: {
    backgroundColor: colors.divider_line_white,
  },
}));

// Component
export default function Exercises () {
  const userType = JSON.parse(localStorage.getItem("AUTH_TOKEN"));
  const history = useHistory();
  const location = history.location;
  const query = querystring.parse(location.search);
  const view = query.view || views.exerciseList.key;
  const currentTab = location.pathname.split('/').pop();
  const queryParams = querystring.parse(location.search);
  const selectedExerciseId = queryParams.exerciseId || null;
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const [openDropdown, setOpenDropdown] = useState(null);
  const classes = useStyles({ selected: openDropdown });
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(UNSELECTED_INDEX);
  const resetSelection = () => setSelectedIndex(!selectedIndex);

  useEffect(() => {
    // Reset the selected exercise when changing My/Nasm Exercise tabs
    resetSelection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const viewMyExercises = () => {
    return updateQuery(views.myExercises.key);
  };

  const viewExercisesList = () => {
    return updateQuery(location.search, { exerciseId: undefined, view: views.exerciseList.key });
  };

  const createExerciseDetailsLink = () => {
    return updateQuery(location.search, { view: views.customExerciseDetails.key, exerciseId: 'new' });
  };

  const viewExerciseDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    if (currentTab === 'my-exercises') {
      return updateQuery(location.search, { 
        view: views.customExerciseDetails.key, 
        exerciseId: exerciseId, 
      });
    }

    // default to nasm-exercises view
    return updateQuery(location.search, { 
      view: views.exerciseDetails.key, 
      exerciseId: exerciseId, 
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewSuperSetsLink = (exerciseId) => {
    return updateQuery(location.search, { exerciseId: exerciseId, view: views.superSetDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      exerciseId: exerciseId,
      view: views.superSetExerciseDetails.key,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };
  
  const viewCircuitLink = (exerciseId) => {
    return updateQuery(location.search, { exerciseId: exerciseId, view: views.circuitDetails.key });
  };

  const viewCircuitExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(location.search, {
      exerciseId: exerciseId,
      view: views.circuitExerciseDetails.key,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const ExercisesTab = () => {
    return (
      <PageToolBar linkOptions={[
        {
          label: 'My Exercises',
          to: MY_EXERCISES_LINK_TO,
        },
        {
          label: 'NASM Exercises',
          to: NASM_EXERCISES_LINK_TO,
        },
      ]}
        color={selectedProfile?.ClubId ? colors.graphite : colors.lightBlue}
      />
    );
  };

  const handleDropdown = (event) => {
    setOpenDropdown(event.currentTarget);
  };

  const handleExerciseMenu = () => {
    handleDropdownClose();
    history.push({
      pathname: '/libraries/exercises/my-exercises',
      search: createExerciseDetailsLink(),
    });
  };

  const handleSuperSetsMenu = () => {
    setSuperSetData({});
    handleDropdownClose();
    history.push({
      pathname: '/libraries/exercises/my-exercises',
      search: viewSuperSetsLink(),
    });
  };

  const handleCircuitMenu = () => {
    setCircuitsData({});
    handleDropdownClose();
    history.push({
      pathname: '/libraries/exercises/my-exercises',
      search: viewCircuitLink(),
    });
  };

  const handleDropdownClose = () => {
    setOpenDropdown(null);
  };

  const renderCreateMenu = () => {
    return (
      <Menu
        id="nav-menu"
        anchorEl={openDropdown}
        keepMounted
        open={Boolean(openDropdown)}
        onClose={handleDropdownClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} 
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          className: classes.menu,
        }}
        PopoverClasses={{
          paper: classes.popoverPaper,
        }}
      >
        <Typography className={classes.createNewTxt}>
          Create New:
        </Typography>
        <Divider className={classes.divider} height={4} />
        <MenuItem className={classes.menuItem} onClick={handleCircuitMenu}>
          <Typography className={classes.menuItemTxt}>Circuit</Typography>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleSuperSetsMenu}>
          <Typography className={classes.menuItemTxt}>Super Set</Typography>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleExerciseMenu}>
          <Typography className={classes.menuItemTxt}>Exercise</Typography>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <>
      <Route exact path="/libraries/exercises">
        <Redirect to={MY_EXERCISES_LINK_TO} />
      </Route>
      <PageHeader title="Exercises" />
      <FloatingCircleButton
        disabled={view === views.customExerciseDetails.key}
        onClick={(e) => {
          handleDropdown(e);
        }}
      />
      {renderCreateMenu()}
      <ExercisesTab />
      <Box>
        {userType?.cc_manager && (
          <Box style={{ padding: "20px 90px 0px 90px" }}>
            <NoticeBar />
          </Box>
        )}
        <PanelContainer>
          <SlidingFlexTransition visible={!!views[view].exerciseList}>
            <Panel height={800}>
              <ExercisesScreen
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                resetSelection={resetSelection}
                viewExerciseDetailsLink={viewExerciseDetailsLink}
                viewSuperSetsLink={viewSuperSetsLink}
                setSuperSetData={setSuperSetData}
                viewCircuitLink={viewCircuitLink}
                setCircuitsData={setCircuitsData}
              />
            </Panel>
          </SlidingFlexTransition>
          <AddSuperSetsPanel
            visible={!!views[view].superSetDetails}
            resetSelection={resetSelection}
            backLink={viewMyExercises}
            superSetData={superSetData}
            setSuperSetData={setSuperSetData}
            viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
          />
          <AddCircuitsPanel
            visible={!!views[view].circuitDetails}
            resetSelection={resetSelection}
            backLink={viewMyExercises}
            circuitData={circuitData}
            setCircuitsData={setCircuitsData}
            viewCircuitExDetailsLink={viewCircuitExDetailsLink}
          />
          <ExerciseDetailsPanel
            visible={!!views[view].exerciseDetails}
            resetSelection={resetSelection}
            backLink={
              views[view].superSetDetails
                ? viewSuperSetsLink
                : views[view].circuitDetails
                ? viewCircuitLink
                : viewExercisesList
            }
            viewExerciseDetailsLink={
              views[view].superSetDetails
                ? viewSuperSetExDetailsLink
                : views[view].circuitDetails
                ? viewCircuitExDetailsLink
                : viewExerciseDetailsLink
            }
          />
          <CustomExercisePanel
            visible={!!views[view].customExerciseDetails}
            backLink={viewExercisesList}
            exerciseId={selectedExerciseId}
            resetSelection={resetSelection}
          />
        </PanelContainer>
      </Box>
    </>
  );
}
