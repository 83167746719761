import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { colors } from "../../styles";
import { PageHeader } from "../../components";
import {
  addPossessiveApostrophe,
  getSelectedDate,
} from "../../util/utilFunctions";
import ClientBreadcrumbs from "../../components/ClientDashboard/ClientBreadcrumbs";
import FloatingButtonDropdown from "../../components/ClientDashboard/FloatingButtonDropdown";
import ClientBigCalendar from "../../components/ClientCalendar/ClientBigCalendar";
import useFetchGroupScheduleByMonth from "../../hooks/workoutSchedule/FetchGroupScheduleByMonth";
import ViewGroupScheduleDialog from "../../components/ViewSchedule/ViewGroupScheduleDialog";

export default function GroupSchedule(props) {
  const { pathMap = {}, selectedGroup = {} } = props;
  const location = useLocation();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(
    getSelectedDate(location?.state?.selectedDate ?? null),
  );
  const [monthViewDate, setMonthViewDate] = useState(
    getSelectedDate(location?.state?.selectedDate ?? null),
  );
  const [isViewScheduleDialogOpen, setViewScheduleDialogOpen] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState("");
  const [selectedWorkoutId, setSelectedWorkoutId] = useState("");
  const [selectedEventDate, setSelectedEventDate] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [events, setEvents] = useState({});

  const rootPath = "/groups/my-groups";
  const groupId = selectedGroup?.id ?? "";
  const groupName = selectedGroup.title ?? "";

  const openViewScheduleDialog = (scheduleId, workoutId, startEventDate) => {
    setViewScheduleDialogOpen(true);
    setSelectedScheduleId(scheduleId);
    setSelectedWorkoutId(workoutId);
    setSelectedEventDate(moment(startEventDate));
  };

  const displayFormattedDate = () => {
    const currentDateSelected = moment(selectedDate);
    return isDateSelectedToday(currentDateSelected, moment())
      ? `Today, ${currentDateSelected.format("MMMM D")}`
      : currentDateSelected.format("dddd, MMMM D");
  };

  const getLinkFromPath = (path) => {
    let link = `${rootPath}`;
    if (groupId) {
      link = `${rootPath}/${groupId}/dashboard/calendar/schedule-${path}`;
    }

    return link;
  };

  const navigateToLink = (link) => {
    history.push(link);
  };

  const navigationItems = [
    {
      label: "Exercises",
      onClick: () => navigateToLink(getLinkFromPath("exercises")),
    },
    {
      label: "Workouts",
      onClick: () => navigateToLink(getLinkFromPath("workouts")),
    },
    {
      label: "Programs",
      onClick: () => navigateToLink(getLinkFromPath("programs")),
    },
  ];

  const {
    groupSchedulesByMonth,
    isDataLoaded,
    error,
  } = useFetchGroupScheduleByMonth({
    groupId: selectedGroup.id,
    date: moment(monthViewDate).startOf("month").format(),
  });

  useEffect(() => {
    if (isDataLoaded && !error) {
      const workoutSchedules = buildWorkoutSchedulesByMonth(
        groupSchedulesByMonth,
      );
      setEvents(workoutSchedules);
    }
  }, [isDataLoaded, error, groupSchedulesByMonth]);

  return (
    <Box>
      <PageHeader
        title={`${addPossessiveApostrophe(groupName)} Schedule`}
        subtitle={displayFormattedDate()}
        color={colors.graphite}
        height={120}
      />
      <FloatingButtonDropdown
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        dropdownHeader="Assign a workout from:"
        navigationItems={navigationItems}
      />
      <ClientBreadcrumbs
        ariaLabel="group-schedule-breadcrumbs"
        pathMap={pathMap}
        pathNamesExclusionList={["groups", selectedGroup.id]}
      />
      <ClientBigCalendar
        selectedDate={selectedDate.toDate()}
        setSelectedDate={setSelectedDate}
        monthViewDate={monthViewDate}
        setMonthViewDate={setMonthViewDate}
        events={events}
        openViewScheduleDialog={openViewScheduleDialog}
      />
      <ViewGroupScheduleDialog
        open={isViewScheduleDialogOpen}
        setOpen={setViewScheduleDialogOpen}
        selectedScheduleId={selectedScheduleId}
        selectedWorkoutId={selectedWorkoutId}
        selectedGroup={selectedGroup}
        eventDate={selectedEventDate}
      />
    </Box>
  );
}

// HELPER FUNCTIONS //

/***
 *
 * @param {Object} schedule - user_schedule_workout object returned from the backend
 * @returns {{allDay: boolean, resource: *, start: Date, end: Date, title: *}}
 */
function createEventFromWorkoutSchedule(schedule) {
  return {
    title: schedule.workout_name,
    start: schedule.localStartDate.toDate(),
    // React Big Calendar requires to have an end-date. Without one,
    // the scheduled workouts won't appear on the calendar as events
    end: schedule.localStartDate.clone().add(1, "hour").toDate(),
    allDay: true,
    resource: {
      scheduleId: schedule.user_schedule_id,
      workoutId: schedule.workout_id,
    },
  };
}

function buildWorkoutSchedulesByMonth(schedules) {
  // key - date string | value - [Event] array of event objects
  const events = {};
  for (const schedule of schedules) {
    // Assuming server time is held in UTC, convert to local timezone
    const startDate = moment(schedule.workout_date).local().startOf("day");
    const startDateStr = startDate.toISOString();

    const event = createEventFromWorkoutSchedule({
      ...schedule,
      localStartDate: startDate,
    });
    if (events[startDateStr]) {
      events[startDateStr].push(event);
    } else {
      events[startDateStr] = [event];
    }
  }

  return events;
}

function isDateSelectedToday(selectedDate, todaysDate) {
  const startOfSelectedDate = selectedDate.startOf("day");
  const startOfToday = todaysDate.startOf("day");
  return (
    startOfSelectedDate.date() === startOfToday.date() &&
    startOfSelectedDate.month() === startOfToday.month() &&
    startOfSelectedDate.year() === startOfToday.year()
  );
}
