import { Box, ButtonBase, Dialog, Typography, makeStyles, styled } from '@material-ui/core';
import React from 'react';
import HeaderIcon from "../../resources/bodymap.svg";
import Body from '../react-body-highlighter';
import { ReactComponent as CloseIcon } from '../../resources/icons-global-close-med-gray.svg';
import { DialogCircleButton } from '../Buttons';
import { colors } from '../../styles';
import resetIcon from '../../resources/icon_reset.svg';

const useStyles = makeStyles({
  profilePaper: {
    display: "flex",
    alignItems: "center",
    width: "560px",
    height: "760px",
    borderRadius: "17px",
    boxShadow: "0 3px 41px 0 rgba(0, 0, 0, 0.22)",
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  saveBtnCont: {
    marginRight: 18,
    width: 100,
    padding: '10px 20px',
    borderRadius: 20,
    backgroundColor: colors.yellow_button_style,
    cursor: 'pointer',
    animation: 'ripple 600ms linear',
  },
  saveBtnLabel: {
    color: colors.white,
    fontSize: 14,
    fontFamily: 'DMSans-Medium,. sans-serif',
    textAlign: 'center',
  },
  bodymapHeadingCont: {
    backgroundColor: colors.divider_line_white,
    padding: '20px',
  },
  bodymapHeading: {
    color: colors.dark_grey,
    fontSize: 17,
    fontFamily: 'DMSans-Bold, sans-serif',
    textAlign: 'center',
  },
  resetMusclesView: ({ isVisible }) => ({
    flex: 7,
    display: 'flex',
    margin: 20,
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: "all .2s",
    opacity: isVisible ? 1 : 0,
  }),
  selectedMusclesLabel: {
    color: colors.dark_grey,
    fontFamily: 'DMSans-Bold, sans-serif',
    fontSize: 14,
    textAlign: 'center',
  },
  btnReset: {
    borderWidth: 1,
    borderColor: colors.dark_grey,
    borderStyle: 'solid',
    padding: 10,
    borderRadius: 20,
    marginLeft: 20,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  resetIcon: { width: 24, height: 24 },
});

const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
  height: 55,
  width: '560px',
  backgroundColor: props => props.color ? props.color: colors.black,
});

const HeaderText = styled(Typography)({
  color: colors.white,
  fontFamily: 'DMSans-Bold, sans-serif',
  fontSize: 24,
  lineHeight: 1.63,
  fontWeight: 'bold',
  marginRight: 10,
});

const HeaderClose = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '16px 32px 0 32px',
  height: 50,
  width: '560px',
  backgroundColor: props => props.color ? props.color: colors.black,
});

const BodyMuscleSelection = ({
  bodyRegionFilters,
  selectedMuscles,
  setSelectedMuscles,
  showBodyMap,
  setShowBodyMap,
  setPendingMuscleGroupFilters,
}) => {

  const classes = useStyles({ isVisible: selectedMuscles?.length > 0 });

  const handleMuscleTap = (tappedMuscle) => {
    const muscle = bodyRegionFilters.find((fltr) => 
      fltr.name?.toLowerCase().replace(' ', '') === tappedMuscle.muscle?.toLowerCase());
    if (muscle) {
      const muscleData = [...selectedMuscles];
      const muscleIndex = muscleData.findIndex(
        (muscleObj) => muscleObj.muscles[0] === tappedMuscle.muscle,
      );
      if (muscleIndex > -1) {
        const muscleObj = muscleData[muscleIndex];
        if (muscleObj.frequency < 1) {
          muscleData[muscleIndex].frequency += 1;
        } else {
          muscleData.splice(muscleIndex, 1);
        }
      } else {
        muscleData.push({
          muscles: [tappedMuscle.muscle],
          frequency: 1,
        });
      }
      setSelectedMuscles(muscleData);
    }
  };

  const updateFilters = () => {
    const filtersCopy = [];
    selectedMuscles.forEach((muscle) => {
      const filter = bodyRegionFilters.find((fltr) => 
        fltr.name?.toLowerCase().replace(' ', '') === muscle.muscles[0]);
      if (filter) {
        filtersCopy.push(filter.id);
      }
    });
    setPendingMuscleGroupFilters(filtersCopy);
    setShowBodyMap(!showBodyMap);
  };

  const resetSelectedMuscles = () => {
    setSelectedMuscles([]);
  };

  const renderResetButton = () => (
    <Box className={classes.resetMusclesView}>
      <Typography className={classes.selectedMusclesLabel}>
        {selectedMuscles?.length}
        {' '}
        Selected
      </Typography>
      <ButtonBase
        className={classes.btnReset}
        onClick={resetSelectedMuscles}
      >
        <img
          src={resetIcon}
          alt={'Reset'}
          className={classes.resetIcon}
        />
      </ButtonBase>
    </Box>
  );

  const renderSaveButton = () => (
    <ButtonBase className={classes.saveBtnCont} onClick={updateFilters}>
      <Typography className={classes.saveBtnLabel}>Save</Typography>
    </ButtonBase>
  );

  return (
    <Dialog
      open={showBodyMap}
      onClose={() => setShowBodyMap(!showBodyMap)}
      classes={{
        paper: classes.profilePaper,
        scrollPaper: classes.scrollPaper,
      }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <Box>
        <HeaderClose color={colors.carbon_black}>
          {renderSaveButton()}
          <DialogCircleButton
            margin="none"
            onClick={() => setShowBodyMap(!showBodyMap)}
          >
            <CloseIcon />
          </DialogCircleButton>
        </HeaderClose>
        <Header color={colors.carbon_black}>
          <HeaderText>Bodymap</HeaderText>
          <img src={HeaderIcon} alt="header-img" />
        </Header>
        <Box
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <Box className={classes.bodymapHeadingCont}>
            <Typography className={classes.bodymapHeading}>
              Select the desired muscle group to filter exercises
            </Typography>
          </Box>
          {renderResetButton()}
          <Box
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "center",
              height: "760px",
            }}
          >
            <Body
              data={selectedMuscles}
              highlightedColors={[colors.radio_blue]}
              svgStyle={{ height: 500 }}
              onClick={handleMuscleTap}
              type="anterior"
              style={{
                borderWidth: 1,
                borderColor: colors.dark_grey,
                borderStyle: "solid",
                borderRadius: 10,
                padding: 10,
              }}
            />
            <Body
              data={selectedMuscles}
              highlightedColors={[colors.radio_blue]}
              svgStyle={{ height: 500 }}
              onClick={handleMuscleTap}
              type="posterior"
              style={{
                borderWidth: 1,
                borderColor: colors.dark_grey,
                borderStyle: "solid",
                borderRadius: 10,
                padding: 10,
                marginLeft: 10,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default BodyMuscleSelection;
