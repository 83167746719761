/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import axios from 'axios';

import nasmApi from '../../api/endpoints';
import { SAVE_STATES } from '../../constants';
import { useExercisesContext } from '../../contexts/ExercisesContext';

import { CircleButton, OvalButton, SaveButton } from '../Buttons';
import {
  LabelTextInput,
  StatelessDropdown,
  UnderlineTextInput,
  MultiSelectDropdown,
  DescriptionTextbox,
} from '../Inputs';
import { IntensityCombobox, UnilateralCombobox, VolumeCombobox } from '../Comboboxes';
import { TermsAndConditionsLink, TermsAndConditionsDisclaimer } from '../Terms';

import Trash from '../../resources/trash.svg';
import { colors } from '../../styles';

import { INTENSITY_KEYS, REST_TEMPO, VOLUME_KEYS } from '../../constants';

import {
  convertFromMinutesAndSecondsToSeconds,
  updateExercisePayload,
  snakeToCamelCase,
  findPrimaryChoice, formatMinutesAndSecondsDisplay,
} from '../../util/utilFunctions';

import { getRestTempoStringForExercise } from '../../util/programUtils';
import { customExerciseValidationSchema } from '../../util/exerciseValidationSchema';

import useFetchFitnessComponentsList from '../../hooks/FetchFitnessComponentsList';
import useFetchMuscleGroupsList from '../../hooks/FetchMuscleGroupsList';
import ExerciseMediaUploader from '../Media/ExerciseMediaUploader';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.63,
    marginTop: 14,
    marginBottom: 20,
  },
});

const SectionText = withStyles({
  root: {
    fontSize: 14,
    fontFamily: 'Roboto',
    color: colors.steel,
  },
})(Typography);

function CreateExerciseContent (props) {
  const classes = useStyles();
  const history = useHistory();
  const { exerciseId, backLink, resetSelection } = props;
  const { refreshExercises } = useExercisesContext();
  const { cc_manager: isCCManager } = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
  const isReadOnly = isCCManager || false;

  const [volumeChoice, setVolumeChoice] = useState('reps');
  const [intensityChoice, setIntensityChoice] = useState('n/a');
  const [imageUrl, setImageUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  

  function cancelMediaUploadRequest () {
    cancelToken.cancel('Uploading CustomExercise Image/Video cancelled');
    setCancelToken(axios.CancelToken.source());
  }

  const [exerciseState, setExerciseState] = useState(
    Object.assign(
      {},
      updateExercisePayload(volumeChoice, intensityChoice, {}),
      initExercisePayload({}),
    ),
  );

  // Initialize exercise data from backend if exercise id is available
  useEffect(() => {
    if (exerciseId !== 'new' && exerciseId) {
      nasmApi.customTrainerExercises.getExercise(exerciseId)
        .then(response => {
          const exercise = response.result;
          if (exercise) {
            const newVolumeChoice = findPrimaryChoice(exercise, VOLUME_KEYS);
            const newIntensityChoice = findPrimaryChoice(exercise, INTENSITY_KEYS);

            setVolumeChoice(newVolumeChoice);
            setIntensityChoice(newIntensityChoice);
            setExerciseState(
              Object.assign(
                updateExercisePayload(newVolumeChoice, newIntensityChoice, exercise),
                initExercisePayload(exercise),
              ),
            );

            setImageUrl(exercise.image_url || null);
            setVideoUrl(exercise.video_url || null);
          }
        }).catch(err => console.error('Failed to fetch exercise data: ', err));
    }
  }, [exerciseId]);

  const { fitnessComponents = [] } = useFetchFitnessComponentsList();
  const { muscleGroups = [] } = useFetchMuscleGroupsList();

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: exerciseState,
    validationSchema: customExerciseValidationSchema,
    onSubmit: async (exercise, formikBag) => {
      await onPressSave(exercise, formikBag);
    },
  });

  function onChangeVolumeChoice (e) {
    formikProps.setFieldValue('volumeChoice', e.target.value, true);
    setVolumeChoice(e.target.value);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeDistanceType (e) {
    formikProps.setFieldValue('distance_units', e.target.value, true);
  }

  function onChangeReps (e) {
    formikProps.setFieldValue('reps', e.target.value, true);
    formikProps.setFieldTouched('reps', true, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeDuration (e) {
    const timeDisplay = formatMinutesAndSecondsDisplay(e.target.value);
    formikProps.setFieldValue('duration', timeDisplay, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeDistance (e) {
    formikProps.setFieldValue('distance', e.target.value, true);
    formikProps.setFieldTouched('distance', true, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeIntensityChoice (e) {
    // set intensity choice here to empty string to trigger conditional error validation
    // when volume choice is set to distance
    formikProps.setFieldValue('intensityChoice',
      e.target.value === 'n/a' ? '' : e.target.value, true);
    setIntensityChoice(e.target.value);
  }

  function onChangeTempo (e) {
    formikProps.setFieldValue('tempo', e.target.value, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangePaceUnits (e) {
    formikProps.setFieldValue('pace_units', e.target.value, true);
  }

  function onChangePaceValue (e) {
    formikProps.setFieldValue('pace', e.target.value, true);
    formikProps.setFieldTouched('pace', true, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeUnilateral (e) {
    formikProps.setFieldValue('allow_unilateral', e.target.checked, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeExerciseSides (e) {
    formikProps.setFieldValue('exercise_sides', e.target.value, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeRestTempo (e) {
    let newRestTempo = 0;
    switch (e.target.value) {
      case 'rest':
        newRestTempo = 1;
        break;
      case 'slow':
        newRestTempo = 2;
        break;
      default:
        newRestTempo = 0;
        break;
    }

    formikProps.setFieldValue('rest_tempo', newRestTempo, true);
    setSaveState(SAVE_STATES.CLICKABLE);
  }

  function onChangeRest (e) {
    const timeDisplay = formatMinutesAndSecondsDisplay(e.target.value);
    formikProps.setFieldValue('rest', timeDisplay, true);
  }

  function resetVolumeFieldErrors () {
    formikProps.setFieldError('reps', undefined);
    formikProps.setFieldError('dur_seconds', undefined);
    formikProps.setFieldError('distance', undefined);
  }

  function resetVolumeValues () {
    formikProps.setFieldValue('reps', '', false);
    formikProps.setFieldValue('duration', '', false);
    formikProps.setFieldValue('distance', '', false);
  }

  function resetIntensityValues () {
    formikProps.setFieldValue('tempo', '', false);
    formikProps.setFieldValue('pace', '', false);
    formikProps.setFieldValue('pace_units', '', false);
  }

  function resetIntensityFieldErrors () {
    formikProps.setFieldError(intensityChoice, undefined);
  }

  async function onPressSave (exercise, formikBag) {
    setSaveState(SAVE_STATES.LOADING);

    const snakeCaseKeys = Object.keys(exercise)
      .filter(key => !['volumeChoice', 'intensityChoice'].includes(key));

    const camelCaseKeys = snakeCaseKeys.map(key => snakeToCamelCase(key));

    const exerciseCopy = {
      imageUrl: imageUrl,
      videoUrl: videoUrl,
    };

    for (let i = 0; i < snakeCaseKeys.length; ++i) {
      const snakeCaseKey = snakeCaseKeys[i];
      const camelCaseKey = camelCaseKeys[i];
      exerciseCopy[camelCaseKey] = exercise[snakeCaseKey];
    }

    if (exercise.id) {
      exerciseCopy.id = exercise.id;
    }

    exerciseCopy.weightUnits = 'lb';
    exerciseCopy.rest = convertFromMinutesAndSecondsToSeconds(exerciseCopy.rest);
    if (exerciseCopy.duration) {
      exerciseCopy.durSeconds = convertFromMinutesAndSecondsToSeconds(exerciseCopy.duration);
      // Remove duration as its not part of the request payload
      delete exerciseCopy.duration;
    } else {
      exerciseCopy.durSeconds = '';
    }

    if (!exerciseCopy.reps) {
      exerciseCopy.reps = '0';
    }

    try {
      if (exerciseCopy.id) {
        await nasmApi.customTrainerExercises.updateExercise(exerciseCopy);
      } else {
        const response = await nasmApi.customTrainerExercises.createExercise(exerciseCopy);
        formikBag.setFieldValue('id', response.result.id, false);
        refreshExercises();
      }
      setSaveState(SAVE_STATES.SAVED);
      resetSelection();
      setTimeout(() => {
        setSaveState(SAVE_STATES.CLICKABLE);
      }, 2000);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert(`Error saving exercise: ${e.data.message}`);
    }
  }

  const onBlurField = (e) => {
    setSaveState(SAVE_STATES.CLICKABLE);
    formikProps.handleBlur(e);
  };

  function onClose () {
    resetSelection();
    cancelMediaUploadRequest();
  }

  function onDeleteExercise (e) {
    const exerciseId = formikProps.values.id;
    if (exerciseId) {
      nasmApi.customTrainerExercises.softDeleteExercise(exerciseId).then(() => {
        history.replace({
          pathname: history.location.pathname,
          search: backLink(),
        });
        history.go(0);
      }).catch(err => {
        window.alert(`Could not delete the exercise: ${err}`);
      });
    }
  }

  let identifierTitle = formikProps.values.name || `this exercise`;

  let deleteDescription = `Deleting ${identifierTitle} will remove it ` +
'from your library and all workouts. Are you sure? ';

  return (
    <Box style={{ height: '95%' }}>
      <form autoComplete='off' onSubmit={formikProps.handleSubmit}>
        <Box className={classes.buttonsContainer}>
          {!isReadOnly && (
            <DeleteButton
              exerciseId={formikProps.values.id}
              onClick={(e) => {
                setShowConfirmDialog(true);
                e.stopPropagation();
              }}
            />
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: !isReadOnly ? "" : "100%",
            }}
          >
            {!isReadOnly && (
              <Box style={{ marginRight: 15 }}>
                <SaveButton saveState={saveState} type="submit" />
              </Box>
            )}
            <Box style={{ marginLeft: 'auto' }}>
              <Link to={{ search: backLink() }}>
                <OvalButton onClick={onClose}>Close</OvalButton>
              </Link>
            </Box>
          </Box>
        </Box>
        <Typography className={classes.header}>
          {!isReadOnly ? "Create your own Exercise" : "Exercise Details"}
        </Typography>
        <Box style={{ marginBottom: 37, display: 'flex', justifyContent: 'center' }}>
          <ExerciseMediaUploader
            imageUrl={imageUrl}
            videoUrl={videoUrl}
            setImageUrl={setImageUrl}
            setVideoUrl={setVideoUrl}
            exerciseId={exerciseId}
            cancelToken={cancelToken}
            setSaveState={setSaveState}
            onCancelMediaUploadRequest={cancelMediaUploadRequest}
          />
        </Box>
        <SectionText>
          Exercise Name
        </SectionText>
        <UnderlineTextInput
          name='name'
          value={formikProps.values.name || ''}
          placeholder='Enter Exercise Name'
          onNameChanged={e => formikProps.setFieldValue('name', e.target.value, false)}
          onNameEditFinished={onBlurField}
          errorText={formikProps.errors.name}
          showError={!!formikProps.touched.name}
        />
        <DescriptionTextbox
          name='description'
          descriptionText={formikProps.values.description}
          errorText={formikProps.errors.description}
          showError={!!formikProps.touched.description}
          onChange={formikProps.handleChange}
          onBlur={onBlurField}
        />
        <SectionText>
          Exercise Details
        </SectionText>
        <MultiSelectDropdown
          label='Fitness Components'
          name='fitness_component_ids'
          values={formikProps.values.fitness_component_ids}
          selectableItems={fitnessComponents}
          onChange={formikProps.handleChange}
          onBlur={onBlurField}
          style={{ marginTop: 13 }}
        />
        <MultiSelectDropdown
          label='Muscle Groups'
          name='muscle_group_ids'
          values={formikProps.values.muscle_group_ids}
          selectableItems={muscleGroups}
          onChange={formikProps.handleChange}
          onBlur={onBlurField}
          showError={!!formikProps.touched.muscle_group_ids}
          errorText={formikProps.errors.muscle_group_ids}
          style={{ marginTop: 23 }}
        />
        <LabelTextInput
          label='Set'
          inputType='number'
          flex={1}
          placeholder='Amount'
          name='sets'
          value={formikProps.values.sets}
          onHandleChange={formikProps.handleChange}
          onHandleBlur={onBlurField}
          errorText={formikProps.errors.sets}
          showError={!!formikProps.touched.sets}
          width='100%'
          style={{ marginTop: 16 }}
        />
        <SectionText>
          Volume
        </SectionText>
        <VolumeCombobox
          reps={formikProps.values.reps}
          duration={formikProps.values.duration}
          distance={formikProps.values.distance}
          onChangeReps={onChangeReps}
          onChangeDuration={onChangeDuration}
          onChangeDistance={onChangeDistance}
          volumeChoice={volumeChoice}
          onChangeVolumeChoice={onChangeVolumeChoice}
          distanceType={formikProps.values.distance_units}
          onChangeDistanceType={onChangeDistanceType}
          resetVolumeValues={resetVolumeValues}
          resetFieldErrors={resetVolumeFieldErrors}
          marginTop={10}
          marginBottom={30}
          fieldErrors={formikProps.errors}
          touchedFields={formikProps.touched}
        />
        <UnilateralCombobox
          checked={!!formikProps.values.allow_unilateral}
          onChangeUnilateral={onChangeUnilateral}
          exerciseSides={formikProps.values.exercise_sides}
          onChangeExerciseSides={onChangeExerciseSides}
        />
        <SectionText>
          Resistance
        </SectionText>
        <LabelTextInput
          label='Weight'
          inputType='number'
          flex={1}
          placeholder='Amount'
          value={formikProps.values.weight}
          name='weight'
          onHandleChange={formikProps.handleChange}
          onHandleBlur={onBlurField}
          errorText={formikProps.errors.weight}
          showError={!!formikProps.touched.weight}
          width='100%'
          style={{ marginTop: 13, marginBottom: 23 }}
        />
        <SectionText>
          Intensity
        </SectionText>
        <IntensityCombobox
          intensityChoice={intensityChoice}
          onChangeIntensityChoice={onChangeIntensityChoice}
          tempo={formikProps.values.tempo}
          onChangeTempo={onChangeTempo}
          paceValue={formikProps.values.pace}
          onChangePaceValue={onChangePaceValue}
          paceUnits={formikProps.values.pace_units}
          onChangePaceUnits={onChangePaceUnits}
          onResetValues={resetIntensityValues}
          resetFieldErrors={resetIntensityFieldErrors}
          marginTop={5}
          marginBottom={30}
          fieldErrors={formikProps.errors}
          touchedFields={formikProps.touched}
        />
        <LabelTextInput
          label='Rest'
          inputType='text'
          flex={1}
          placeholder='00:00'
          name='rest'
          value={formikProps.values.rest}
          onHandleChange={onChangeRest}
          onHandleBlur={onBlurField}
          errorText={formikProps.errors.rest}
          showError={!!formikProps.touched.rest}
          width='100%'
          style={{ marginTop: 16 }}

        />
        <Box style={{ marginTop: 16, marginBottom: 26 }}>
          <StatelessDropdown
            label='Rest Tempo'
            options={REST_TEMPO}
            width='100%'
            currentValue={getRestTempoStringForExercise(formikProps.values).toLowerCase()}
            onOptionChanged={onChangeRestTempo}
            dropdownSpacing={10}
          />
        </Box>
      </form>
      <TermsAndConditionsDisclaimer>
        By uploading Outside Content, as defined in the EDGE Terms,
        I agree to the EDGE <TermsAndConditionsLink /> concerning Outside Content.
        NASM is not responsible or liable for Outside Content or any issues arising from Outside Content.
      </TermsAndConditionsDisclaimer>
       <ConfirmDialog
              title='Delete Exercise'
              description={deleteDescription}
              actionButtonTitle='Delete'
              open={showConfirmDialog}
              onClose={() => setShowConfirmDialog(false)}
              handleConfirmAction={()=> onDeleteExercise()}
            />
    </Box>
  );
}

function DeleteButton (props) {
  const { exerciseId, onClick } = props;
  if (exerciseId) {
    return (
      <CircleButton onClick={onClick}>
        <img alt='trash icon' src={Trash} />
      </CircleButton>
    );
  }

  // Render an empty div so the other buttons e.g. Save and close remain to the upper right corner
  return <div />;
}

function initExercisePayload (exercise) {
  return {
    name: exercise.name ?? '',
    description: exercise.description ?? '',
    fitness_component_ids: exercise?.fitness_components?.map(f => f.id) ?? [],
    muscle_group_ids: exercise?.muscle_groups?.map(m => m.id) ?? [],
    allow_unilateral: !!exercise.allow_unilateral,
  };
}

CreateExerciseContent.propTypes = {
  exerciseId: PropTypes.string,
  backLink: PropTypes.func.isRequired,
  viewExerciseDetailsLink: PropTypes.func,
  resetSelection: PropTypes.func.isRequired,
};

export default CreateExerciseContent;
