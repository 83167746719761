import React from 'react';
import moment from 'moment';

import { Calendar, momentLocalizer, Navigate, Views } from 'react-big-calendar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { EVENT_CONTENT_COLORS, EVENT_DATE_COLORS } from './colors';

// Styles //
import './sass/rbc-styles.scss';
import { colors } from '../../styles';

import RightArrow from '../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg';
import LeftArrow from '../../resources/icons-arrows-buttons-left-arrow-dark-gray.svg';

const DateCell = withStyles({
  root: {
    flex: 1,
    backgroundColor: 'transparent',
    '& + div': {
      borderLeft: '1px solid #DDD',
    },
  },
})(Box);

const MonthDateLabelContainer = withStyles({
  root: {
    display: 'flex',
  },
})(Box);

const MonthDateLabelExtras = withStyles({
  root: {
    display: 'flex',
    flex: 1,
  },
})(Box);

const MonthDateContainer = withStyles({
  root: {
    cursor: 'pointer',
  },
})(Box);

const useMonthDateLabelStyles = makeStyles({
  root: props => ({
    width: '26px',
    height: '26px',
    borderRadius: '34px',
    margin: '12px',
    fontWeight: 'bold',
    fontFamily: 'DMSans',
    fontSize: '15px',
    backgroundColor: props.bgColor,
    color: props.textColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
});

function MonthDateLabel (props) {
  const classes = useMonthDateLabelStyles(props);
  return (
    <Box className={classes.root}>
      {props.children}
    </Box>
  );
}

const useEventContainerStyles = makeStyles({
  root: props => ({
    height: '30px',
    overflow: 'hidden',
    backgroundColor: props.bgColor,
    marginRight: '1%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
});

function EventContainer (props) {
  const classes = useEventContainerStyles(props);
  return <Box className={classes.root}>{props.children}</Box>;
}

// CUSTOM VIEWS //

const useToolbarStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '22px',
    fontSize: '22px',
  },
  arrowPadding: {
    padding: '0 18px',
    display: 'flex',
    alignItems: 'center',
  },
  arrowStyle: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.light_blue_grey_32,
      // transform: 'scaleX(0.5)',
      transition: 'background-color 150ms ease-in-out',
    },
    '&:active': {
      backgroundColor: 'rgba(206, 211, 215, 0.5)',
      transition: 'background-color 150ms ease-in-out',
    },
  },
  textContainer: {
    minWidth: '170px',
  },
  text: {
    fontSize: '22px',
    textAlign: 'center',
  },
});

/**
 *
 * @param props
 * @return {JSX.Element}
 */
function Toolbar (props) {
  const classes = useToolbarStyles();
  const title = moment(props.date).format('MMMM YYYY');

  return (
    <Box className={classes.root}>
      <Box className={classes.arrowPadding}>
        <img
          src={LeftArrow}
          alt='previous-month-arrow'
          className={classes.arrowStyle}
          onClick={() => props.onNavigate(Navigate.PREVIOUS)}
        />
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>{title}</Typography>
      </Box>
      <Box className={classes.arrowPadding}>
        <img
          src={RightArrow}
          alt='next-month-arrow'
          className={classes.arrowStyle}
          onClick={() => props.onNavigate(Navigate.NEXT)}
        />
      </Box>
    </Box>
  );
}

const useEventContentStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    fontSize: '11px',
  },
  statusBar: props => ({
    backgroundColor: props.indicatorColor,
    height: 'inherit',
    minWidth: '4px',
  }),
  contentText: props => ({
    color: props.textColor,
    margin: '0 12px 0 8px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '14px',
    maxHeight: '28px',
    whiteSpace: 'normal',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }),
});

/**
 * Component that represents the contents of an event. Here we will want to evaluate the event status to decide
 * what color the indicator bar and text need to be.
 * @param {Object} props - Set of values received from the library
 * @param {Boolean} props.continuesAfter - Flag that describes if the current event extends after the current slot.
 * @param {Boolean} props.continuesPrior - Flag that describes if the current event extends before the current slot.
 * @param {Object} props.event - The event data object. Displayed here are the built in properties, but an event
 *    can be any shape and include many more custom properties.
 * @param {String} props.event.title - Title to be displayed (already processed by the title accessor).
 * @param {Date} props.event.start - Javascript date for the start of the event.
 * @param {Date} props.event.end - Javascript date for the end of the event.
 * @param {Boolean} props.event.allDay - Flag describing if the event is marked as "allDay". The "start" and "end"
 *    would still exist but the UI would ignore its range and display across the entire timeline for the day. (Not
 *    really applicable in the "month" view but important for the drilldown views.
 * @param {Boolean} props.isAllDay
 * @param {Object} props.localizer
 * @param {Date} props.slotEnd - The date of the last slot in the row this event segment exists in.
 * @param {Date} props.slotStart - The date of the first slot in the row this event segment exists in.
 * @param {String} props.title - Title to be displayed (already processed by the title accessor).
 * @return {JSX.Element}
 */
function EventContents (props) {
  const { event } = props;
  const isPast = moment(event.start).isBefore(moment(), 'day');
  const isFuture = moment(event.start).isAfter(moment(), 'day');

  let indicatorColor = EVENT_CONTENT_COLORS.TODAY.bar;
  let textColor = EVENT_CONTENT_COLORS.TODAY.text;
  if (isPast) {
    // TODO Insert logic here that will check the status of the event/workout if its complete or incomplete
    indicatorColor = EVENT_CONTENT_COLORS.INCOMPLETE.bar;
    textColor = EVENT_CONTENT_COLORS.INCOMPLETE.text;
  }
  if (isFuture) {
    indicatorColor = EVENT_CONTENT_COLORS.UPCOMING.bar;
    textColor = EVENT_CONTENT_COLORS.UPCOMING.text;
  }

  const classes = useEventContentStyles({ indicatorColor, textColor });

  return (
    <Box className={classes.root}>
      <Box className={classes.statusBar} />
      <Box className={classes.contentText}>
        {props.event.title}
      </Box>
    </Box>
  );
}

/**
 * Use to control the event container
 * @param {Object} props - Set of values received from the library
 * @param {Object} props.accessors - Object who's properties are the methods used to access properties of an event.
 *    These methods could be supplied when initializing the Calendar and could contain logic to "remap" or "transform"
 *    the property within the event respective to the method name. Useful if custom logic deviates from the provided
 *    event information and we want to reprocess event data using the predefined logic within the accessors.
 * @param {JSX.Element} props.children - Contains the component node with the contents of the event from the library.
 * @param {Object} props.components - Object whose properties are functions that can be used as component factories.
 * @param {Boolean} props.continuesAfter - Flag that describes if the current event extends after the current slot.
 * @param {Boolean} props.continuesPrior - Flag that describes if the current event extends before the current slot.
 * @param {Object} props.event - The event data object. Displayed here are the built in properties, but an event
 *    can be any shape and include many more custom properties.
 * @param {String} props.event.title - Title to be displayed (already processed by the title accessor).
 * @param {Date} props.event.start - Javascript date for the start of the event.
 * @param {Date} props.event.end - Javascript date for the end of the event.
 * @param {Boolean} props.event.allDay - Flag describing if the event is marked as "allDay". The "start" and "end"
 *    would still exist but the UI would ignore its range and display across the entire timeline for the day. (Not
 *    really applicable in the "month" view but important for the drilldown views.
 * @param {Object} props.getters - Apparently meant to be a list of methods that would return classNames and styles
 *    to be applied to the current event slot. Not too sure about this one
 * @param {Object} props.localizer
 * @param {Function} props.onDoubleCLick - Callback method to capture double click events.
 * @param {Function} props.onKeyPress - Callback method to capture key press events while event is focused.
 * @param {Function} props.onSelect - Callback method to capture when an event has been selected.
 * @param {Boolean} props.resizeable
 * @param {Boolean} props.selected
 * @param {Date} props.slotEnd - The date of the last slot in the row this event segment exists in.
 * @param {Date} props.slotStart - The date of the first slot in the row this event segment exists in.
 * @param {String} props.type - Name of the type of event wrapper. In "month" view, this value will be "date".
 * @param {String} props.viewDate -
 * @return {JSX.Element}
 */
function EventWrapper (props) {
  const startMoment = moment(props.event.start);
  const viewDateMoment = moment(props.viewDate);
  const now = moment();
  const offRangeStart = viewDateMoment.clone().startOf('month');
  const offRangeEnd = viewDateMoment.clone().endOf('month');
  // See https://momentjs.com/docs/#/query/is-between/ (inclusivity)
  const includeStartAndEndDates = '[]';
  const isOffRange = !startMoment.isBetween(offRangeStart, offRangeEnd, 'day', includeStartAndEndDates);

  // Hide the event if it falls outside the current month selected
  if (isOffRange) {
    return null;
  }

  const isPast = startMoment.isBefore(now, 'day');
  const isFuture = startMoment.isAfter(now, 'day');

  let bgColor = EVENT_CONTENT_COLORS.TODAY.bg;
  if (isPast) {
    // TODO: Insert logic to evaluate the event/workout to determine if it should show as complete or incomplete.
    bgColor = EVENT_CONTENT_COLORS.INCOMPLETE.bg;
  }
  if (isFuture) {
    bgColor = EVENT_CONTENT_COLORS.UPCOMING.bg;
  }

  return (
    <EventContainer
      name='event-wrapper'
      bgColor={bgColor}
    >
      {props.children}
    </EventContainer>
  );
}

/**
 * Use to control the background color of the "day slot" in the calendar month view.
 * @param {Object} props - Set of values received from the library
 * @param {JSX.Element} props.children - RBC's html element
 * @param {Date[]} props.range - Array of dates the current cell is grouped with. This
 *    array represents the row of dates in the calendar.
 * @param {Date} props.value - The date the current cell represents.
 * @return {JSX.Element}
 */
function DateCellWrapper (props) {
  // Q: Why get the month here and do nothing with it?
  // const currentMonth = moment().get('month');
  // Use the isOffRange value if we need to change the background color of date cells outside the month
  // const isOffRange = moment(props.value).get('month') !== currentMonth;

  return (
    <DateCell name='month-date-cell-wrapper-styled' />
  );
}

/**
 * Controls the calendar date label such as (1, 2, ..., 10, 11, ..., 31).
 * Gives us the ability to control position, style, and click action
 * @param {Object} props - Set of values received from the library
 * @param {Date} props.date - Javascript date object that label is based from.
 * @param {String} props.drilldownView - Library's property to decide what view to transition
 *    to when selecting the date cell.
 * @param {Boolean} props.isOfRange - A flag that describes if the date/label is outside the
 *    range of the current month being viewed.
 * @param {String} props.label - The string to be displayed in the cell.
 * @param {Function} props.onDrillDown - Callback to pass to onClick event to transition to
 *    the view specified in props.drilldownView.
 * @param {Object} props.eventMap - An object dictionary of key-value pairs where the key = calendar date
 *    and value = [events]. Calendar date labels associated with dates that have events are the only date slots
 *    that receive a background color around the date label.
 * @param {Function} props.onSelectDate - Callback that should be fired when the date label is clicked
 * @param {Date} props.selectedDate - Date of the currently selected day
 * @return {JSX.Element}
 */
function MonthDateCellLabelContainer (props) {
  if (props.isOffRange) {
    return null;
  }

  const isSelected = moment(props.selectedDate).isSame(moment(props.date), 'day');
  const isPast = moment(props.date).isBefore(moment(), 'day');
  const isFuture = moment(props.date).isAfter(moment(), 'day');

  let bgColor = EVENT_DATE_COLORS.NORMAL.bg;
  const textColor = isSelected ? EVENT_DATE_COLORS.SELECTED.text : EVENT_DATE_COLORS.NORMAL.text;

  if (isSelected) {
    bgColor = EVENT_DATE_COLORS.SELECTED.bg;
  } else if (!props.eventMap || !props.eventMap[props.date.toISOString()]) {
    bgColor = 'transparent';
  } else {
    if (isPast) {
      // TODO: Here extra logic will be needed to evaluate event status
      bgColor = EVENT_DATE_COLORS.INCOMPLETE.bg;
    }
    if (isFuture) {
      bgColor = EVENT_DATE_COLORS.NORMAL.bg;
    }
  }

  return (
    <MonthDateLabelContainer>
      <MonthDateLabelExtras>
        {/* Insert possible status dots here */}
      </MonthDateLabelExtras>

      <MonthDateContainer onClick={() => props.onSelectDate(props.date)}>
        <MonthDateLabel
          name='month-date-cell-label'
          bgColor={bgColor}
          textColor={textColor}
        >
          {props.label}
        </MonthDateLabel>
      </MonthDateContainer>
    </MonthDateLabelContainer>
  );
}

/**
 * Custom component for rendering the Month Views column headers.
 * @param {Object} props - Set of values received from the library
 * @param {Date} props.date - A javascript date object that represents the first rows date of the respective label.
 *    If the label is "Mon" for monday, this date will be the first monday listed in the current calender view.
 * @param {String} props.label - The string to display in the column within the month header row.
 * @param {Object} props.localizer
 * @return {JSX.Element}
 */
function MonthHeader (props) {
  return (
    <div className='my-month-header'>
      {props.label.toUpperCase()}
    </div>
  );
}

// END CUSTOM VIEWS //

const useStyles = makeStyles({
  divContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '64px',
    backgroundColor: colors.white,
  },
  customCalendarStyle: {
    boxSizing: 'content-box',
    // removing these properties will cause
    // the cells on the calendar to not appear
    width: '90%',
    // minHeight: '660px',
  },
});

export default function ClientBigCalendar (props) {
  /*
    events object
    key - date
    value - [Event] (array of Event objects)

    Event {
      title: string,
      start: Date,
      end: Date,
      allDay?: boolean
      resource?: any,
    }

    Reference: https://jquense.github.io/react-big-calendar/examples/index.html#prop-events
  */
  const {
    selectedDate,
    setSelectedDate,
    monthViewDate,
    setMonthViewDate,
    events = {},
    openViewScheduleDialog = (scheduleId, workoutId, startEventDate) => {},
    ...rest
  } = props;

  const handleDateSelected = (date) => {
    setSelectedDate(moment(date));
  };

  const classes = useStyles();
  const calendarProps = {
    className: classes.customCalendarStyle,
    defaultView: Views.MONTH,
    date: monthViewDate.toDate(),
    localizer: momentLocalizer(moment),
    events: Object.values(events).flat(),
    popup: true,
    formats: {
      dateFormat: 'D',
    },

    // Callback functions //
    onSelectEvent: (event, e) => {
      // console.log(JSON.stringify(event, null, 2));
      openViewScheduleDialog(event.resource.scheduleId, event.resource.workoutId, event.start);
    },
    // invoked when month is changed
    onNavigate: date => {
      setMonthViewDate(moment(date));
    },

    // Components //
    components: {
      event: EventContents,
      toolbar: Toolbar,
      eventWrapper: props => <EventWrapper viewDate={monthViewDate} {...props} />,
      dateCellWrapper: props => <DateCellWrapper {...props} />,
      month: {
        header: MonthHeader,
        dateHeader: props =>
          <MonthDateCellLabelContainer
            {...props}
            selectedDate={selectedDate}
            eventMap={events}
            onSelectDate={handleDateSelected}
          />,
      },
    },
  };

  return (
    <Box className={classes.divContainer}>
      <Calendar {...calendarProps} {...rest} />
    </Box>
  );
}
