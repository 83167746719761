import React from 'react';
import PropTypes from 'prop-types';
import { styled, makeStyles } from '@material-ui/core/styles';
import { Dialog, Typography } from '@material-ui/core';
import { backdropStyles, colors } from '../../styles';
import { DialogCircleButton, SaveButton } from '../Buttons';

import { ReactComponent as CloseIcon } from '../../resources/icons-global-close-med-gray.svg';

const useDialogStyles = makeStyles({
  scrollPaper: {
    alignItems: 'flex-start',
  },
  paper: {
    width: '554px',
    height: 'auto',
    borderRadius: '17px',
    border: `1px solid ${colors.selected_highlight_copy_6}`,
  },
});

const CardHeader = styled('div')({
  backgroundColor: props => props.headercolor,
  height: '120px',
});

const TopBtnCont = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 12px',
});

const Header = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: colors.white,
  marginLeft: '33px',
});

function DashboardDialog (props) {
  const {
    open,
    onClose,
    saveState,
    headerColor,
    title,
    onClickSave,
    children,
    isReadOnly,
  } = props;
  const classes = useDialogStyles();

  return (
    <Dialog
      open={open}
      classes={{
        scrollPaper: classes.scrollPaper,
        paper: classes.paper,
      }}
      BackdropProps={{
        style: backdropStyles,
      }}
    >
      <CardHeader headercolor={headerColor}>
        <TopBtnCont>
          {!isReadOnly && (
            <SaveButton
              saveState={saveState}
              onClick={onClickSave}
              margin="0 20px 0 0"
            />
          )}
          <DialogCircleButton onClick={onClose}>
            <CloseIcon />
          </DialogCircleButton>
        </TopBtnCont>
        <Header>{title}</Header>
      </CardHeader>
      { children }
    </Dialog>
  );
}

export default DashboardDialog;

DashboardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  headerColor: PropTypes.string,
  title: PropTypes.string,
  saveState: PropTypes.string,
  onClickSave: PropTypes.func,
  isReadOnly: PropTypes.bool,
};
