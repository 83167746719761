/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIosTwoTone } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import useStyles from "./style";
import SendMessageIcon from "../../stories/assets/sendMessage.svg";
import MyDashboardPageHeader from "../../components/PageHeaders/MyDashboardPageHeader";
import { useClientsContext } from "../../contexts/ClientsContext";
import useAllActivity from "../../hooks/AllActivity";
import { selectClient } from "../../reducers/selectedClientReducer";
import CCDashboardSkeleton from "../../components/LoadingStates/CCDashboardSkeleton";
import { RookActivity } from "../../components/Activity/RookActivity";
import { WorkoutActivity } from "../../components/Activity/WorkoutActivity";
import NoticeBar from "../../components/Notice/NoticeBar";

export default function DashboardNavigator() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const selectedProfile = useSelector(
    (state) => state?.clubConnect?.selectedProfile,
  );
  const {
    loading,
    error,
    allClients,
    filteredClients: clients,
    fetchClients,
  } = useClientsContext();
  const { activities } = useAllActivity();
  const userType = JSON.parse(localStorage.getItem("AUTH_TOKEN"));


  useEffect(() => {
    fetchClients(selectedProfile?.ClubId, selectedProfile?.Locations?.Id);
  }, [currentUser.id, fetchClients, selectedProfile]);

  const isInitialLoading = loading && !error && allClients.length === 0;

  const onClickClient = (client) => {
    dispatch(selectClient(client ?? {}));
    const isActive = client.client_user.active_under_current_trainer;
    if (isActive || selectedProfile?.ClubId) {
      history.push(
        `/clients/my-clients/${client.first_name.toLowerCase()}/dashboard`,
      );
    }
  };

  const onClickTrainerReport = (type) => {
    let navUrl = "/libraries";
    switch (type) {
      case "exercise":
        navUrl = "/libraries/exercises/my-exercises";
        break;
      case "workout":
        navUrl = "/libraries/workouts/my-workouts";
        break;
      case "program":
        navUrl = "/libraries/programs/my-programs";
        break;
      default:
        navUrl = "/libraries";
    }
    history.push(`${navUrl}`);
  };

  const renderActivity = (index, activity) => {
    if (activity.activity_type === "rook_data_source_activities") {
      return (
        <div key={`#${index}-${activity?.activity_id}`}>
          <RookActivity activity={activity} />
          {index < activities.length - 1 && <Divider />}
        </div>
      );
    } else {
      return (
        <div key={`#${index}-${activity?.activity_id}`}>
          <WorkoutActivity currentUser={currentUser} activity={activity} />
          {index < activities.length - 1 && <Divider />}
        </div>
      );
    }
  };

  return (
    <>
      <Box>
        <MyDashboardPageHeader loading={false} />
      </Box>

      {isInitialLoading ? (
        <CCDashboardSkeleton />
      ) : (
        <Box sx={{ flexGrow: 1, padding: 2 }} marginX={15} marginY={4}>
          {userType?.cc_manager && <NoticeBar />}
          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item xs={12} sm={4}>
              <Paper style={{ marginBottom: 20, borderRadius: 10 }}>
                <AppBar position="static" className={classes.cardHeader}>
                  <Toolbar>
                    <Typography
                      variant="h6"
                      className={classes.cardHeaderTitile}
                    >
                      Trainer Account
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Box className={classes.trainerView}>
                  <Box className={classes.trainerAvatarView}>
                    <Avatar
                      src={currentUser?.avatar_url}
                      alt="Profile"
                      variant="circular"
                      className={classes.trainerAvatar}
                    />
                  </Box>
                  <Box className={classes.trainerNameView}>
                    <Typography variant="h6" className={classes.tainerName}>
                      {currentUser?.full_name}
                    </Typography>
                    <Box className={classes.tainerEmail}>
                      <img
                        src={SendMessageIcon}
                        alt="SendMessageIcon"
                        className={classes.emailIcon}
                      />
                      <Typography className={classes.email}>
                        {currentUser?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>

              <Paper sx={{ padding: 2 }}>
                <AppBar position="static" className={classes.cardHeader}>
                  <Toolbar>
                    <Typography
                      variant="h6"
                      className={classes.cardHeaderTitile}
                    >
                      Trainer Report
                    </Typography>
                  </Toolbar>
                </AppBar>
                <List>
                  <ListItem
                    button
                    onClick={() => onClickTrainerReport("exercise")}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.cardItemTitle}>
                          Exercises
                        </Typography>
                      }
                    />
                    <ArrowForwardIosTwoTone className={classes.arrowIcon1} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => onClickTrainerReport("workout")}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.cardItemTitle}>
                          Workouts
                        </Typography>
                      }
                    />
                    <ArrowForwardIosTwoTone className={classes.arrowIcon1} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => onClickTrainerReport("program")}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.cardItemTitle}>
                          Programs
                        </Typography>
                      }
                    />
                    <ArrowForwardIosTwoTone className={classes.arrowIcon1} />
                  </ListItem>
                </List>
              </Paper>
            </Grid>

            {/* Middle Column */}
            <Grid item xs={12} sm={4}>
              <Paper sx={{ padding: 2 }}>
                <AppBar position="static" className={classes.cardHeader}>
                  <Toolbar>
                    <Typography
                      variant="h6"
                      className={classes.cardHeaderTitile}
                    >
                      Latest Activity
                    </Typography>
                  </Toolbar>
                </AppBar>
                <List className={classes.scrollList}>
                  {activities && activities?.length > 0 ? (
                    activities.map((activity, index) =>
                      renderActivity(index, activity),
                    )
                  ) : (
                    <ListItem className={classes.activityItem}>
                      <ListItemText
                        primary={
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            className={classes.noDateText}
                          >
                            No Activity
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Grid>

            {/* Right Column */}
            {!userType?.cc_manager && (
              <Grid item xs={12} sm={4}>
                <Paper sx={{ padding: 2 }}>
                  <AppBar position="static" className={classes.cardHeader}>
                    <Toolbar
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h6"
                        className={classes.cardHeaderTitile}
                      >
                        {currentUser?.first_name + "'s Clients"}
                      </Typography>
                      <Badge
                        badgeContent={
                          <Typography className={classes.badgeCount}>
                            {clients?.length ?? 0}
                          </Typography>
                        }
                      />
                    </Toolbar>
                  </AppBar>
                  <List className={classes.scrollList}>
                    {clients && clients?.length > 0 ? (
                      clients.map((client, index) => (
                        <div key={index}>
                          <ListItem
                            className={classes.clientItem}
                            onClick={() => onClickClient(client)}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={client?.avatar_url}
                                alt={client?.full_name}
                                variant="circle"
                                className={classes.clientAvatar}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  component="div"
                                  variant="body2"
                                  color="textPrimary"
                                  className={classes.clientName}
                                >
                                  {client?.full_name}
                                </Typography>
                              }
                            />
                            <ArrowForwardIosTwoTone
                              className={classes.arrowIcon1}
                            />
                          </ListItem>

                          {index < clients.length - 1 && <Divider />}
                        </div>
                      ))
                    ) : (
                      <ListItem className={classes.clientItem}>
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              className={classes.noDateText}
                            >
                              No Clients
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
}
