import React, { useState } from 'react';
import { styled, makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import UpdateProfile from './UpdateProfile';
import ManageAccount from '../AccountSettings/ManageAccountController';
import OvalButton from '../Buttons/OvalButton';
import DialogCircleButton from '../ViewSchedule/DialogCircleButton';
import { UA_PERMISSION_TYPES, USER_ROLES } from '../../constants';

import { colors } from '../../styles';

import { ReactComponent as CloseIcon } from '../../resources/icons-global-close-med-gray.svg';
import phone from '../../resources/fill-1.svg';
import mail from '../../resources/icons-global-contact-email-gray.svg';
import placeholderIcon from '../../resources/user-dashboard-client-profile-with-no-photo.svg';

const useStyles = makeStyles({
  accountPaper: {
    width: '554px',
    height: 'auto',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
  },
  profilePaper: {
    width: '578px',
    height: props => props.isClient ? '780px': '680px',
    borderRadius: '17px',
    boxShadow: '0 3px 41px 0 rgba(0, 0, 0, 0.22)',
    overflow: 'scroll',
  },
  scrollPaper: {
    alignItems: 'flex-start',
  },
});

const TransparentBtnCont = styled('div')({
  position: 'relative',
});

const TransparentBtnOverlay = styled('div')({
  position: 'absolute',
  width: 85,
  height: 36,
  borderRadius: 19,
  backgroundColor: colors.white,
  cursor: 'pointer',
  zIndex: 1,
  opacity: 0,
  '&:hover': {
    opacity: 0.06,
  },
});

const DialogContainer = styled('div')({
  position: 'relative',
});

const HeaderBkg = styled('div')({
  backgroundColor: colors.steel,
  height: 'auto',
  minHeight: '140px',
});

const TopBtnCont = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingTop: '20px',
});

const NameCont = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  width: '100%',
});

const NameRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '70%',
  paddingLeft: '10px',
  paddingRight: '20px',
  marginTop: '30px',
});

const NameLeftView = styled('div')({
  width: '30%',
});

const BasicInfoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minWidth: '500px',
  marginTop: '-53px',
  marginLeft: '30px',
});

const BasicInfoText = styled('div')({
  marginLeft: '24px',
  marginRight: '24px',
  marginTop: '53px',
  width: '100%',
});

const ProfilePicture = styled('img')({
  width: '123px',
  height: '123px',
  borderRadius: '61px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.grey_text_color,
  zIndex: 2,
  objectFit: 'cover',
});

const FullName = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: colors.white,
  maxWidth: '70%',
});

const StartDate = styled(Typography)({
  textAlign: 'right',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '11px',
  color: colors.white,
});

const SubTextContainer = styled('div')({});

const TextAndIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '30px',
});

const PhoneIcon = styled('img')({
  width: '15px',
  height: '15px',
  marginRight: '10px',
});

const MailIcon = styled(PhoneIcon)({
  height: '14px',
});

const DialogContent = styled('div')({
  padding: '0 30px',
  marginTop: '100px',
  height: 'auto',
});

const UpgradePlanContainer = styled('div')({
  position: 'absolute',
  bottom: 20,
  right: 30,
});

const UpgradePlan = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '190px',
});

const PermissionText = styled(Typography)({
  fontSize: '9px',
  color: colors.steel,
});

const UpgradeLink = styled(Typography)({
  fontSize: '12px',
  color: colors.radio_blue,
  textDecoration: 'underline',
  cursor: 'pointer',
});

function TransparentBtn ({ onClick }) {
  return (
    <TransparentBtnCont onClick={onClick}>
      <TransparentBtnOverlay />
      <OvalButton
        fontWeight='bold'
        fontSize={19}
        width={85}
        height={36}
        backgroundcolor='transparent'
        color={colors.white}
        border={`2px solid ${colors.white}`}
      >
        Edit
      </OvalButton>
    </TransparentBtnCont>
  );
}

/**
 * @return {JSX.Element|null}
 */
function UpgradeText ({ subscription, onClickUpgrade, userRole }) {
  if (userRole === USER_ROLES.CLIENT) { return null; }
  if (subscription !== UA_PERMISSION_TYPES.EDGE_TRAINER_PRO) {

    const subType = subscription === UA_PERMISSION_TYPES.EDGE_TRAINER_PLUS ? 'Plus' : 'Base';
    return (
      <UpgradePlanContainer>
        <UpgradePlan>
          <PermissionText>{`Your account is using EDGE ${subType}`}</PermissionText>
          <UpgradeLink onClick={onClickUpgrade}>Upgrade</UpgradeLink>
        </UpgradePlan>
      </UpgradePlanContainer>
    );
  }
  return null;
}

/**
 * @return {JSX|null}
 */
function ClientStartDate (props) {
  const { isClient, startTrainDate } = props;

  if(!isClient) {
    return null;
  }

  return (
    <StartDate>
      {`Start Date ${moment(startTrainDate).format('M/D/YY')}`}
    </StartDate>
  );
}

function AccountSettings (props) {
  const history = useHistory();
  const { currentUser, isOpen, onClose, profileAvatar, clubId = null } = props;
  const isClient = currentUser.nasm_role === USER_ROLES.CLIENT;
  const classes = useStyles({ isClient });

  const [showProfile, setShowProfile] = useState(false);

  const currentUserManager = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  const onOpenProfile = () => {
    setShowProfile(true);
  };

  const onCloseProfile = () => {
    setShowProfile(false);
  };

  const onClickUpgrade = () => {
    setShowProfile(false);
    onClose();
    history.push('/clients/view-subscriptions');
  };

  return (
    <>
      <Dialog
        open={isOpen}
        classes={{ paper: classes.accountPaper, scrollPaper: classes.scrollPaper }}
        BackdropProps={{
          style: {
            backgroundColor: colors.backdrop_tint,
            opacity: 0.35,
          },
        }}
      >
        <DialogContainer>
          <HeaderBkg>
            <TopBtnCont>
            { !currentUserManager?.cc_manager ? (<TransparentBtn onClick={onOpenProfile} />) : <TransparentBtnCont /> }
              <DialogCircleButton onClick={onClose}>
                <CloseIcon />
              </DialogCircleButton>
            </TopBtnCont>
              <NameCont>
                <NameLeftView />
                <NameRow>
                  <FullName>{currentUser.full_name}</FullName>
                  <ClientStartDate
                    isClient={isClient}
                    startTrainDate={currentUser?.client_user?.started_training_at}
                  />
              </NameRow>
              </NameCont>
          </HeaderBkg>
          <BasicInfoContainer>
            <Box>
              <ProfilePicture
                src={profileAvatar || currentUser.avatar_url || placeholderIcon}
                onError={(e) => {
                  e.target.src = placeholderIcon;
                }}
              />
            </Box>
            <BasicInfoText>
              <SubTextContainer>
                <TextAndIcon>
                  <PhoneIcon src={phone} alt='phone-number' />
                  <div>{currentUser.phone_number || 'Phone number'}</div>
                </TextAndIcon>
                <TextAndIcon>
                  <MailIcon src={mail} alt='email address' />
                  <div>{currentUser.email || 'Email'}</div>
                </TextAndIcon>
              </SubTextContainer>
            </BasicInfoText>
          </BasicInfoContainer>
          <DialogContent>
            <ManageAccount user={currentUser} clubId={clubId} />
            <UpgradeText
              subscription={currentUser.subscription}
              onClickUpgrade={onClickUpgrade}
              userRole={currentUser.nasm_role}
            />
          </DialogContent>
        </DialogContainer>
      </Dialog>
      <Dialog
        open={showProfile}
        classes={{ paper: classes.profilePaper, scrollPaper: classes.scrollPaper }}
        BackdropProps={{
          style: {
            backgroundColor: colors.backdrop_tint,
            opacity: 0.35,
          },
        }}
      >
        <UpdateProfile
          visible={showProfile}
          user={currentUser}
          onClose={onCloseProfile}
        />
      </Dialog>
    </>
  );
}

export default AccountSettings;
