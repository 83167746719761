import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CardMedia, Box, Menu, MenuItem } from '@material-ui/core';
import FileUploadInput from '../FileUploadInput';

import { colors } from '../../../styles';

import {
  EditUploadButton,
  FullScreenButton,
  PlayPauseOverlay,
} from './MediaControls';

const useStyles = makeStyles({
  mediaOverlayRoot: props => ({
    position: 'absolute',
    width: 'inherit',
    height: 'inherit',
    top: 0,
    // turns off pointer events for this overlay to allow user to interact with
    // built in html5 video controls
    pointerEvents: props.isPlaying ? 'none' : 'all',
  }),
  mediaPreview: {
    objectFit: 'contain',
    width: 'inherit',
    height: 'inherit',
  },
  playButtonWrapper: props => ({
    width: 'inherit',
    height: props.componentType === 'video' ? '85%' : '100%',
    backgroundColor: props.isPlaying ? 'transparent' : 'rgba(0, 0, 0, 0.25)',
    transition: 'background-color .3s',
    cursor: 'pointer',
  }),
  marginSpacing: {
    marginTop: 10,
  },
  editMenu: {
    width: 114,
    paddingTop: 0,
    paddingBottom: 0,
  },
  editMenuItem: {
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
    '&.Mui-focusVisible': {
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: colors.selected_highlight_copy_6,
      },
    },
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 2.6,
    display: 'flex',
    justifyContent: 'center',
  },
  popoverPaper: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.22)',
    borderRadius: '6px',
    border: `solid 1px ${colors.selected_highlight_copy_6}`,
  },
  mediaTopRightControls: props => ({
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '25%',
    width: '100%',
    zIndex: 2, // to allow buttons to be clickable
    visibility: props.isPlaying || props.isSeeking ? 'hidden' : 'visible',
  }),
  videoPlaybackControls: {
    backdropFilter: 'blur(50px)',
    backgroundBlendMode: 'lighten, lighten, normal',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8))',
    color: 'red',
    height: '15%',
    display: 'flex',
    alignItems: 'center',
  },
});

function ExerciseMediaPreview (props) {
  const { fileType, src, onUploadBegin, isReadOnly } = props;
  const componentType = fileType.includes('video') ? 'video' : 'img';

  const [anchorEl, setAnchorEl] = useState(null);
  const [editButtonClicked, setEditButtonClicked] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);

  const inputFileRef = useRef();
  const videoRef = useRef();
  const playPauseRef = useRef();

  const classes = useStyles({ isPlaying, isSeeking });

  function onOpenEditMenu (e) {
    setAnchorEl(e.target);
    setEditButtonClicked(true);
    // prevent parent element from triggering this callback
    e.stopPropagation();
  }

  function onCloseEditMenu (e) {
    setAnchorEl(null);
    setEditButtonClicked(false);
    // prevent parent element from triggering this callback
    e.stopPropagation();
  }

  function onClickUploadButton (e) {
    inputFileRef.current.click();
    setAnchorEl(null);
    setEditButtonClicked(false);
    e.stopPropagation();
  }

  const onVideoPlay = useCallback((e) => {
    setIsPlaying(true);
  }, []);

  const onVideoPause = useCallback((e) => {
    setIsPlaying(false);
  }, []);

  const onVideoSeeking = useCallback(() => {
    setIsSeeking(true);
  }, []);

  const onVideoSeeked = useCallback(() => {
    setIsSeeking(false);
  }, []);

  const onVideoEnd = useCallback(() => {
    setIsPlaying(false);
  }, []);

  function onTogglePlay () {
    if (componentType === 'video') {
      setIsPlaying(prevState => {
        if (prevState === true) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }

        return !prevState;
      });
    }
  }

  function onToggleFullScreen (e) {
    if (componentType === 'video') {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullScreen#browser_compatibility
      videoRef.current.requestFullscreen().catch(e => {
        console.error('Error turning on full screen mode: ', e);
      });
    }

    e.stopPropagation();
  }

  useEffect(() => {
    const videoElem = videoRef.current;
    // videoElem.addEventListener('timeupdate', onUpdateVideoTime);
    videoElem.addEventListener('play', onVideoPlay);
    videoElem.addEventListener('pause', onVideoPause);
    videoElem.addEventListener('seeking', onVideoSeeking);
    videoElem.addEventListener('seeked', onVideoSeeked);
    videoElem.addEventListener('ended', onVideoEnd);

    return () => {
      videoElem.removeEventListener('play', onVideoPlay);
      videoElem.removeEventListener('pause', onVideoPause);
      videoElem.removeEventListener('seeking', onVideoSeeking);
      videoElem.removeEventListener('seeked', onVideoSeeked);
      videoElem.removeEventListener('ended', onVideoEnd);
    };
  }, [onVideoPlay, onVideoPause, onVideoSeeking, onVideoSeeked, onVideoEnd, componentType]);

  return (
    <Box id='media-container' style={{ width: '526px', height: '235px', position: 'relative' }}>
      <CardMedia
        ref={videoRef}
        id='custom-exercise-media'
        classes={{
          media: classes.mediaPreview,
        }}
        component={componentType}
        src={src}
        controls
        // Removes ability to dock video to the bottom right corner of screen
        disablePictureInPicture
        controlsList='nodownload'
      />
      <Box ref={playPauseRef} className={classes.mediaOverlayRoot}>
        <Box
          className={classes.playButtonWrapper}
          onClick={onTogglePlay}
        >
          <Box className={classes.mediaTopRightControls}>
            {!isReadOnly && (
              <EditUploadButton
                isClicked={editButtonClicked}
                onClick={onOpenEditMenu}
              >
                <Menu
                  className={classes.marginSpacing}
                  keepMounted
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={onCloseEditMenu}
                  // Set to null so that anchorOrigin and transformOrigin
                  // can be set without warnings/errors
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  MenuListProps={{ className: classes.editMenu }}
                  PopoverClasses={{ paper: classes.popoverPaper }}
                >
                  <MenuItem
                    className={classes.editMenuItem}
                    onClick={onClickUploadButton}
                  >
                    <FileUploadInput
                      id="media-edit-upload"
                      inputFileRef={inputFileRef}
                      onUploadBegin={onUploadBegin}
                    />
                    Replace
                  </MenuItem>
                </Menu>
              </EditUploadButton>
            )}
            {componentType === 'video' && <FullScreenButton onClick={onToggleFullScreen} />}
          </Box>
          <PlayPauseOverlay componentType={componentType} isActive={isPlaying || isSeeking} />
        </Box>
      </Box>
    </Box>
  );
}

ExerciseMediaPreview.propTypes = {
  fileType: PropTypes.string.isRequired,
  // can be either a url or a base64 string
  src: PropTypes.string.isRequired,
  onUploadBegin: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default ExerciseMediaPreview;
