import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { colors } from "../../styles";
import InfoIcon from "../../resources/info.svg";

const useStyles = makeStyles({
  noticeView: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    backgroundColor: colors.mediumBlue,
    padding: 10,
    marginTop: 15,
    marginBottom: 30,
    borderRadius: 5,
  },
  noticeTitile: {
    fontSize: 12,
    color: colors.white,
    paddingLeft: 6,
  },
});

const NoticeBar = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noticeView}>
      <img src={InfoIcon} alt="notice-info-icon" />
      <Typography variant="h6" className={classes.noticeTitile}>
        You are viewing a Trainer’s Dashboard. All actions and data shown are
        specific to this trainer.
      </Typography>
    </Box>
  );
};

export default NoticeBar;
