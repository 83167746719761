import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import querystring from 'query-string';
import { colors } from '../../styles';
import {
  initReducer,
  WORKOUT_BUTTONS_VISIBILITY_ACTIONS,
  workoutButtonsVisibilityReducer,
} from '../../reducers/local/workoutButtonsVisibilityReducer';
import { updateQuery } from '../../util/utilFunctions';
import { PageHeader } from '../../components';

import ClientBreadcrumbs from '../../components/ClientDashboard/ClientBreadcrumbs';
import PanelContainer from '../../components/Panels/PanelContainer';
import ExerciseDetailsPanel from '../../components/Panels/ExerciseDetailsPanel';
import ScheduleDialog from '../../components/SchedulePopover/ScheduleDialog';
import { rescheduleProgramContext } from '../../reducers/programContextReducer';
import { clearProgram, scheduleProgram, selectScheduledProgram } from '../../reducers/selectedProgramReducer';
import ProgramDragDropContext from '../../components/Programs/ProgramDragDropContext';
import WorkoutSectionExerciseContext from '../../components/WorkoutSectionExerciseContext';
import EditExercisePanel from '../../components/Panels/EditExercisePanel';
import { clearWorkout } from '../../reducers/selectedWorkoutReducer';
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog';
import AddSuperSetsPanel from '../../components/Panels/AddSuperSetsPanel';
import AddCircuitsPanel from '../../components/Panels/AddCircuitsPanel';

const useStyles = makeStyles({
  root: {
    height: '100%',
    backgroundColor: colors.white,
  },
});

function CustomizeProgram (props) {
  const {
    pathMap,
    sectionIds,
  } = props;

  const history = useHistory();
  const location = history.location;
  const dispatch = useDispatch();
  const classes = useStyles();

  const query = querystring.parse(history.location.search, { parseBooleans: true, parseNumbers: true });
  const view = query?.view || 'programDetails';

  const client = useSelector(state => state.selectedClient?.client ?? {});
  const clientFirstName = client.first_name.toLowerCase() ?? 'client';
  const clientFirstNameUpper = clientFirstName?.charAt(0).toUpperCase() + clientFirstName.slice(1) ?? 'Client';
  const scheduledProgramData = useSelector(state => state.selectedProgram ?? {});
  const scheduledProgram = scheduledProgramData?.program;
  const programId = query.programId;
  const scheduleId = query.scheduleId;

  const [selectedExerciseDraggableId, setSelectedExerciseDraggableId] = useState('none');
  const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});

  const programStartDate = query?.programStartDate || '';
  const programEndDate = query?.programEndDate || programStartDate || '';

  const [workoutButtonsVisibilityFlags, localDispatch] =
    useReducer(workoutButtonsVisibilityReducer, {}, initReducer);

  function updateButtonVisibilityFlags (
    {
      isCopyButtonVisible,
      isSaveButtonVisible,
      isAddExercisesButtonVisible,
      isAddButtonVisible,
    },
  ) {
    localDispatch({
      type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS,
      payload: {
        showCopyButton: isCopyButtonVisible,
        showSaveButton: isSaveButtonVisible,
        showAddExercisesButton: isAddExercisesButtonVisible,
        showAddButton: isAddButtonVisible,
      },
    });
  }

  useEffect(() => {
    dispatch(rescheduleProgramContext());
    dispatch(selectScheduledProgram({
      program_id: programId,
      schedule_id: scheduleId,
      programStartDate,
      programEndDate,
    }));
  }, [dispatch, programId, scheduleId, programStartDate, programEndDate]);

  useEffect(() => {
    updateButtonVisibilityFlags({
      isCopyButtonVisible: false,
      isSaveButtonVisible: false,
      isAddExercisesButtonVisible: true,
      isAddButtonVisible: false,
    });
  }, [location, dispatch]);

  useEffect(() => {
    if (!scheduledProgramData.programChanged) return;
    window.addEventListener('beforeunload', handlePressBack);
    return () => window.removeEventListener('beforeunload', handlePressBack);
  }, [scheduledProgramData.programChanged]);

  const views = {
    programDetails: {
      key: 'programDetails',
      programDetails: true,
    },
    addWorkouts: {
      key: 'addWorkouts',
      programDetails: true,
      addWorkouts: true,
    },
    workoutDetails: {
      key: 'workoutDetails',
      workoutDetails: true,
      programDetails: true,
    },
    addExercises: {
      key: 'addExercises',
      workoutDetails: true,
      addExercises: true,
    },
    editExercise: {
      key: 'editExercise',
      editExercise: true,
      workoutDetails: true,
    },
    viewExercise: {
      key: 'viewExercise',
      addExercises: true,
      viewExercise: true,
      // can be viewed together with viewExercise
      // when selecting an exercise from an NASM Program
      workoutDetails: false,
    },
    superSetDetails: {
      key: 'superSetDetails',
      workoutDetails: true,
      superSetDetails: true,
    },
    superSetExerciseDetails: {
      key: 'superSetExerciseDetails',
      superSetDetails: true,
      viewExercise: true,
      workoutDetails: false,
    },
    circuitDetails: {
      key: 'circuitDetails',
      workoutDetails: true,
      circuitDetails: true,
    },
    circuitExerciseDetails: {
      key: 'circuitExerciseDetails',
      circuitDetails: true,
      viewExercise: true,
      workoutDetails: false,
    },
    progressionRegressionExDetails: {
      key: 'progressionRegressionExDetails',
      progressionRegressionExDetails: true,
      viewExercise: true,
      workoutDetails: true,
    },
  };

  const resetSelectedExercise = () => setSelectedExerciseDraggableId('none');

  const getProgramUrl = () => {
    const query = updateQuery(history.location.search, { view: views.programDetails.key, programId });
    return {
      pathname: history.location.pathname,
      search: query,
    };
  };

  const getWorkoutUrlParams = (workoutId) => {
    const query = updateQuery(history.location.search, { view: views.workoutDetails.key, workoutId: workoutId });
    return {
      pathname: history.location.pathname,
      search: query,
    };
  };

  const viewWorkoutDetailsLink = (workoutId) => {
    return updateQuery(history.location.search, {
      view: views.workoutDetails.key,
      exerciseId: undefined,
      programId: programId,
      workoutId: workoutId,
    });
  };

  const viewExerciseLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(history.location.search, { 
      view: views.viewExercise.key, 
      exerciseId: exerciseId, 
      showProgressionsRegressions,
    });
  };

  const editExerciseLink = () => {
    return updateQuery(location.search, { view: views.editExercise.key });
  };

  const addExercisesListLink = () => {
    return updateQuery(location.search, { view: views.addExercises.key, exerciseId: undefined });
  };

  const viewSuperSetsLink = () => {
    return updateQuery(location.search, { view: views.superSetDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      view: views.superSetExerciseDetails.key,
      exerciseId, 
      exerciseKey,
      showProgressionsRegressions,
    });
  };

  const viewCircuitLink = () => {
    return updateQuery(location.search, { view: views.circuitDetails.key });
  };

  const viewCircuitExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    return updateQuery(location.search, { 
      exerciseId, 
      exerciseKey, 
      view: views.circuitExerciseDetails.key,
      showProgressionsRegressions,
    });
  };

  const viewProgressionRegressionExDetailsLink = (exerciseId, exerciseKey, showProgressionsRegressions = true) => {
    if(views[view].superSetExerciseDetails){
      views.progressionRegressionExDetails.superSetDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else if (views[view].circuitExerciseDetails) {
      views.progressionRegressionExDetails.circuitDetails = true;
      views.progressionRegressionExDetails.superSetDetails = false;
      views.progressionRegressionExDetails.workoutDetails = false;
    } else {
      views.progressionRegressionExDetails.workoutDetails = true;
      views.progressionRegressionExDetails.circuitDetails = false;
      views.progressionRegressionExDetails.superSetDetails = false;
    }
    return updateQuery(location.search, {
      view: views.progressionRegressionExDetails.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const navigateBackToCalendar = (startDate) => {
    const state = { selectedDate: startDate };
    history.replace(`/clients/my-clients/${clientFirstName}/dashboard/calendar`, state);
  };

  const onClickSchedule = () => {
    setIsScheduleDialogOpen(true);
  };

  const onCloseScheduleDialog = useCallback(() => {
    setIsScheduleDialogOpen(false);
  }, []);

  const onFail = useCallback((errorMessage) => {
    window.alert(errorMessage);
    onCloseScheduleDialog();
  }, [onCloseScheduleDialog]);

  const clearSelectedSchedule = () => {
    dispatch(clearProgram());
    dispatch(clearWorkout());
  };

  const onScheduleRepeat = async (startDate, endDate) => {
    try {
      await dispatch(scheduleProgram(startDate, endDate));
      navigateBackToCalendar(startDate);
    } catch (e) {
      onFail(e.message);
    } finally {
      onCloseScheduleDialog();
      clearSelectedSchedule();
    }
  };

  const handlePressBack = (e) => {
    e.preventDefault();
    setDiscardDialogOpen(true);
  };

  const queryMap = {
    programDetails: [
      { label: 'Edit Program', link: getProgramUrl },
    ],
    workoutDetails: [
      { label: 'Edit Program', link: getProgramUrl },
      { label: 'Workout Details', link: getWorkoutUrlParams, data: query.workoutId },
    ],
    addWorkouts: [
      { label: 'Edit Program', link: getProgramUrl },
      { label: 'Add Workouts', link: getWorkoutUrlParams, data: query.workoutId },
    ],
    addExercises: [
      { label: 'Edit Program', link: getProgramUrl },
      { label: 'Workout Details', link: getWorkoutUrlParams, data: query.workoutId },
      { label: 'Add Exercises', link: addExercisesListLink },
    ],
    editExercise: [
      { label: 'Edit Program', link: getProgramUrl },
      { label: 'Workout Details', link: getWorkoutUrlParams, data: query.workoutId },
      { label: 'Edit Exercise', link: editExerciseLink, data: query.exerciseId },
    ],
    viewExercise: [
      { label: 'Edit Program', link: getProgramUrl },
      { label: 'Add Workouts', link: getWorkoutUrlParams, data: query.workoutId },
      { label: 'Preview Exercises', link: addExercisesListLink },
      { label: 'Preview Exercise', link: viewExerciseLink },
    ],
    superSetDetails: [
      { label: 'Superset Details', link: () => {} }, // this link will never be clickable
    ],
    superSetExerciseDetails: [
      { label: 'Superset Details', link: () => {} }, // this link will never be clickable
    ],
    circuitDetails: [
      { label: 'Circuit Details', link: () => { } }, // this link will never be clickable
    ],
    circuitExerciseDetails: [
      { label: 'Circuit Details', link: () => { } }, // this link will never be clickable
    ],
    progressionRegressionExDetails: [
      { label: 'progression Regression Details', link: () => { } }, // this link will never be clickable
    ],
  };

  return (
    <Box className={classes.root}>
      <PageHeader
        title={`Customize ${clientFirstNameUpper}'s Program`}
        color={colors.graphite}
        height={120}
      />
      <ClientBreadcrumbs
        ariaLabel='client-schedule-breadcrumbs'
        pathMap={pathMap}
        queryMap={queryMap}
        view={view === 'programList' ? '' : view}
        pathNamesExclusionList={['clients', clientFirstName]}
      />
      <PanelContainer>
        <ProgramDragDropContext
          programDetailsVisible={!!views[view].programDetails}
          addWorkoutsVisible={!!views[view].addWorkouts}
          currentView={view}
          programId={scheduledProgram?.id}
          getWorkoutUrlParams={getWorkoutUrlParams}
          programDetailsKey={views.programDetails.key}
          addWorkoutKey={views.addWorkouts.key}
          resetProgramIndex={() => {}}
          viewWorkoutDetailsLink={viewWorkoutDetailsLink}
          programDetailsLink={getProgramUrl}
          copyProgramLink={() => {}}
          backLink={`/clients/my-clients/${clientFirstName.toLowerCase()}/dashboard/calendar`}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          onClickSchedule={onClickSchedule}
        />
        <WorkoutSectionExerciseContext
          isWorkoutSectionsVisible={!!views[view].workoutDetails}
          isAddExercisesVisible={!!views[view].addExercises}
          sectionIds={sectionIds}

          // Navigation
          currentView={view}
          addExercisesKey={views.addExercises.key}
          addExercisesListLink={addExercisesListLink}
          backLink={getProgramUrl}
          copyWorkoutLink={() => {}}
          editExerciseLink={editExerciseLink}
          viewExerciseLink={viewExerciseLink}
          viewWorkoutDetailsLink={viewWorkoutDetailsLink}
          resetWorkoutIndex={() => {}}
          selectedExerciseDraggableId={selectedExerciseDraggableId}
          setSelectedExerciseDraggableId={setSelectedExerciseDraggableId}
          resetSelectedExercise={resetSelectedExercise}
          workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
          updateButtonVisibilityFlags={updateButtonVisibilityFlags}
          viewSuperSetsLink={viewSuperSetsLink}
          setSuperSetData={setSuperSetData}
          viewCircuitLink={viewCircuitLink}
          setCircuitsData={setCircuitsData}
        />
        <AddSuperSetsPanel
          visible={!!views[view].superSetDetails}
          resetSelection={resetSelectedExercise}
          backLink={viewWorkoutDetailsLink}
          superSetData={superSetData}
          setSuperSetData={setSuperSetData}
          viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
          isAutoSaveEnabled
        />
        <AddCircuitsPanel
          visible={!!views[view].circuitDetails}
          resetSelection={resetSelectedExercise}
          backLink={viewWorkoutDetailsLink}
          circuitData={circuitData}
          setCircuitsData={setCircuitsData}
          viewCircuitExDetailsLink={viewCircuitExDetailsLink}
          isAutoSaveEnabled
        />
        <EditExercisePanel
          backLink={viewWorkoutDetailsLink}
          visible={!!views[view].editExercise}
          isAutoSaveEnabled
          viewExerciseDetailsLink={viewProgressionRegressionExDetailsLink}
        />
        <ExerciseDetailsPanel
          visible={!!views[view].viewExercise}
          resetSelection={resetSelectedExercise}
          isAutoSaveEnabled={workoutButtonsVisibilityFlags.isAutoSaveEnabled}
          isRescheduling
          backLink={
            views[view].superSetDetails ? viewSuperSetsLink 
            : views[view].circuitDetails ? viewCircuitLink 
            : viewWorkoutDetailsLink 
            }
          viewExerciseDetailsLink={
            views[view].superSetDetails ? viewSuperSetExDetailsLink 
            : views[view].circuitDetails ? viewCircuitExDetailsLink 
            : viewProgressionRegressionExDetailsLink}
        />
        <ScheduleDialog
          backdropFilterVisible
          open={isScheduleDialogOpen}
          workouts={Object.values(scheduledProgramData?.entities?.workouts ?? {})}
          onClose={onCloseScheduleDialog}
          onScheduleOnce={async () => {}}
          onScheduleRepeat={onScheduleRepeat}
          onPrepareWorkouts={() => {}}
          disableSaveButton={false}
          onlyRepeat
        />
         <ConfirmDialog
           open={discardDialogOpen}
           onClose={() => setDiscardDialogOpen(false)}
           handleConfirmAction={navigateBackToCalendar}
           title='Discard Unsaved Changes?'
           description='If you navigate away from this page without saving first, all progress will be lost.'
           actionButtonTitle='Discard'
         />
      </PanelContainer>
    </Box>
  );
}

export default CustomizeProgram;
