import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import MilestoneList from './MilestoneList';

function MilestoneDragDropContext (props) {
  const {
    milestones,
    loading,
    isAdding,
    isDeleting,
    onFinishEditing,
    deleteMilestone,
    reorderMilestones,
    markMilestoneAsComplete,
    isFtu,
    isReadOnly,
  } = props;

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) { return; }
    reorderMilestones(source.index, destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <MilestoneList
        milestones={milestones}
        onFinishEditing={onFinishEditing}
        isAdding={isAdding}
        isDeleting={isDeleting}
        deleteMilestone={deleteMilestone}
        loading={loading}
        markMilestoneAsComplete={markMilestoneAsComplete}
        isFtu={isFtu}
        isReadOnly={isReadOnly}
      />
    </DragDropContext>
  );
}

export default MilestoneDragDropContext;
