import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled, makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { colors } from "../../styles";
import { LabelTextInput, UnderlineTextInput } from "../../components/Inputs";
import iconAdd from "../../resources/icon-Plus-gray.svg";
import ChooseExerciseDialog from "./ChooseExerciseDialog";
import thumbnailPlaceholder from "../../resources/image-exercise-placeholder@2x.png";
import arrowIcon from "../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg";
import editIcon from "../../resources/edit-orange.png";
import nasmApi from "../../api/endpoints";
import { SAVE_STATES } from "../../constants";
import { CircleButton, OvalButton, SaveButton } from "../../components/Buttons";
import ROUTINE_TYPES from "../../util/RoutineTypes";
import Trash from '../../resources/trash.svg';
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import { editExercise, removeExercise, removeExerciseAndSaveWorkout } from "../../reducers/selectedWorkoutReducer";
import { programContexts } from "../../reducers/programContextReducer";
import ProgRegIconSet from "../../components/ProgressionRegressionIconSet";

const initialExerciseState = [
  {
    name: "Exercise 1",
    id: 0,
    exercise: {},
  },
  {
    name: "Exercise 2",
    id: 1,
    exercise: {},
  },
];

const useStyles = makeStyles({
  dialogPaper: {
    width: "554px",
    height: "600px",
    borderRadius: "5px",
    boxShadow: "0 3px 41px 0 rgba(0, 0, 0, 0.22)",
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  inputContainer: {
    padding: "20px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  dialogHeading: {
    fontSize: "24px",
    fontWeight: 700,
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
  },
  text: {
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginBottom: "13px",
  },
  repsText: {
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginTop: "13px",
    marginBottom: "5px",
  },
  repsInput: (props) => ({
    marginTop: props.isReadOnly ? "13px" : 0,
    marginBottom: props.isReadOnly ? "5px" : 0,
  }),
  labelInputStyles: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
  },
  chooseExerciseTxt: {
    color: colors.steel,
    fontWeight: 400,
    fontSize: 14,
    marginLeft: "20px",
    marginBottom: "10px",
  },
  addExerciseContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${colors.divider_line_white}`,
    marginBottom: "15px",
    padding: "10px 20px",
  },
  addExerciseTxt: {
    fontWeight: 600,
    fontSize: 14,
  },
  addIcon: {
    width: "22px",
    height: "22px",
  },
  recordContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 80,
    marginBottom: "20px",
    borderBottom: "1px solid #e6e6e6",
    borderLeft: "none",
    borderRight: "none",
    "&:hover": {
      backgroundColor: colors.baby_blue,
      cursor: "pointer",
    },
  },
  boxLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imageContainer: {
    paddingLeft: 12,
    display: "flex",
  },
  thumbnail: {
    borderRadius: 6,
    objectFit: "cover",
  },
  nameContainer: {
    paddingLeft: 23,
    fontSize: 17,
    fontWeight: 500,
    color: colors.black,
  },
  arrowIconCont: {
    marginRight: 10,
  },
});

const labelInputStyles = {
  fontFamily: "Roboto, sans-serif",
  fontSize: "14px",
};

const DialogContainer = styled("div")({
  position: "relative",
});

function CreateSuperSet(props) {
  const {
    superSetData,
    setSuperSetData,
    backLink,
    resetSelection,
    viewSuperSetExDetailsLink = () => {},
    isAutoSaveEnabled,
    isDeletable,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [superSetName, setSuperSetName] = useState("");
  const [reps, setReps] = useState("");
  const [showChooseExerciseDialog, setShowChooseExerciseDialog] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState({});
  const [selectedExercises, setSelectedExercises] = useState(initialExerciseState);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showError, setShowError] = useState(false);
  const [exerciseNumber, setExerciseNumber] = useState(0);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const sectionId = location?.state?.sectionId ?? null;
  const workoutExercise = location?.state?.exercise ?? {};
  const workout = location?.state?.workout ?? {};
  const { cc_manager: isCCManager } = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
  const programContext = useSelector(state => state.programContext.context);
  const isReadOnly = (programContext === programContexts.SCHEDULING) || isCCManager;
  const classes = useStyles({isReadOnly});

  useEffect(() => {
    if (Object.keys(superSetData).length) {
      updateExercises(superSetData);
      setSuperSetName(superSetData.title);
      setReps(superSetData.reps);
      setIsUpdate(true);
    }
  }, [superSetData]);

  useEffect(() => {
    window.addEventListener(`progRegToggle_${ROUTINE_TYPES.SUPER_SET}`, onReceiveToggleEvent);

    return () => {
      window.removeEventListener(`progRegToggle_${ROUTINE_TYPES.SUPER_SET}`, onReceiveToggleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReceiveToggleEvent = (event) => {
    const { updatedData } = event.detail;
    if (updatedData) {
      updateExercises(updatedData);
    }
  };

  function updateExercises(superset) {
    setSelectedExercises([
      {
        name: "Exercise 1",
        id: 0,
        exercise: superset.compound_routine_exercises[0]?.exercise ?? superset.compound_routine_exercises[0],
      },
      {
        name: "Exercise 2",
        id: 1,
        exercise: superset.compound_routine_exercises[1]?.exercise ?? superset.compound_routine_exercises[1],
      },
    ]);
  }

  function onClose() {
    setSelectedExercises(initialExerciseState);
    setSuperSetName("");
    setReps("");
    setExerciseNumber(0);
    setSuperSetData({});
  }

  const onSavePressed = () => {
    // prevent triggering multiple async requests
    if (saveState === SAVE_STATES.LOADING) {
      return;
    }

    const isSupersetConfigured = (
      superSetName &&
      reps &&
      selectedExercises[0].exercise?.id &&
      selectedExercises[1].exercise?.id
    );

    if (isSupersetConfigured) {
      setSaveState(SAVE_STATES.LOADING);
      const jsonData = {
        title: superSetName.trim(),
        reps: Number(reps),
        exercises: selectedExercises.map((sec) => ({
          exercise_id: sec.exercise?.id,
          ordinal: sec.id,
        })),
        routine_type: ROUTINE_TYPES.SUPER_SET,
      };
      if (isUpdate) {
        jsonData.compound_routine_id = superSetData.id || superSetData.compound_routine_id;
        if (programContext === programContexts.RESCHEDULING) {
          updateSuperSetLocally(jsonData);
        } else {
          updateSuperSet(jsonData);
        }
      } else {
        createSuperSet(jsonData);
      }
    } else {
      alert("Please fill all the details");
    }
  };

  const updateSuperSetLocally = async (jsonData) => {
    const updatedSuperSet = {
      ...jsonData,
      id: jsonData.compound_routine_id,
      key: superSetData?.key,
      compound_routine_exercises: selectedExercises.map((sec) => ({
        ...sec.exercise,
        ordinal: sec.id,
        compound_routine_id: jsonData.compound_routine_id,
      })),
    };
    try {
      setSaveState(SAVE_STATES.LOADING);
      await dispatch(editExercise(updatedSuperSet));
      setSaveState(SAVE_STATES.SAVED);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert(`Error saving changes to superset: ${e.message}`);
    }
  };

  function onNameChanged(event) {
    const newInputValue = event.target.value;
    if (newInputValue !== superSetName) {
      setSuperSetName(newInputValue);
    }
  }

  const onOpenChooseExerciseDialog = (exerciseNum) => {
    setExerciseNumber(exerciseNum);
    setShowChooseExerciseDialog(true);
  };

  const onDoneChooseExerciseDialog = () => {
    setSelectedExercises((prevState) => {
      const updatedExercises = prevState.map((exercise) => {
        if (exercise.id === exerciseNumber) {
          return {
            ...exercise,
            exercise: selectedExercise,
          };
        }
        return exercise;
      });
      return updatedExercises;
    });
    setShowChooseExerciseDialog(false);
  };

  const onCloseChooseExerciseDialog = () => {
    setShowChooseExerciseDialog(false);
  };

  const createSuperSet = async (jsonData) => {
    await nasmApi.supersets
      .createSuperSet(jsonData)
      .then((res) => {
        if (res) {
          setSaveState(SAVE_STATES.SAVED);
          alert("Superset created successfully");
          onClose();
          history.replace(backLink());
          window.location.reload();
        } else {
          // response wasn't received try again
          setSaveState(SAVE_STATES.CLICKABLE);
        }
      })
      .catch((err) => {
        setSaveState(SAVE_STATES.ERROR);
        alert(err?.message || "Something went wrong! Please try again later.");
      });
  };

  const updateSuperSet = async (jsonData) => {
    await nasmApi.supersets
      .updateSuperSetById(jsonData)
      .then((res) => {
        if (res) {
          setSaveState(SAVE_STATES.SAVED);
          alert("Superset updated successfully");
          history.replace(backLink());
          if (window.location.pathname.includes('/libraries/exercises')) {
            window.location.reload();
          }
        } else {
          // res isn't available... try again
          setSaveState(SAVE_STATES.CLICKABLE);
        }
      })
      .catch((err) => {
        setSaveState(SAVE_STATES.ERROR);
        alert(err?.message || "Something went wrong! Please try again later.");
      });
  };

  const handleRepsInput = (event) => {
    if (event.target.value < 11) {
      setReps(event.target.value);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  function onOpenConfirmationDialog () {
    setShowConfirmDeleteDialog(true);
  }

  function onCloseConfirmationDialog () {
    setShowConfirmDeleteDialog(false);
  }

  function onPressDelete () {
    onCloseConfirmationDialog();
    if (sectionId == null) {
      window.alert(`Failed to delete Superset. Could not find 
      ${workoutExercise?.title ?? 'this Superset'} in any of the` +
        ` workout sections in ${workout?.name ?? 'this workout'}`);
    } else {
      // When a workout is duplicated, the trainer must manually save the workout
      if (isAutoSaveEnabled) {
        dispatch(removeExerciseAndSaveWorkout({ exercise: workoutExercise, sectionId }))
          .then(() => {
            setShowConfirmDeleteDialog(false);
            resetSelection();
            history.push(backLink());
          })
          .catch((e) => {
            console.error(`Failed to delete Superset error:\n${JSON.stringify(e, null, 2)}`);
            window.alert(`Failed to delete the Superset: 
            ${workoutExercise.title} from ${workout?.name ?? 'this workout'}`);
          });
      } else {
        dispatch(removeExercise({ exercise: workoutExercise, sectionId }));
        history.goBack();
      }
    }
  }

  const renderChooseExercise = () => (
    <ChooseExerciseDialog
      isOpen={showChooseExerciseDialog}
      onClose={onCloseChooseExerciseDialog}
      onDone={onDoneChooseExerciseDialog}
      setSelectedExercise={setSelectedExercise}
    />
  );

  const renderButtonContainer = () => {
    return (
      <Box className={classes.buttonsContainer}>
        {(isDeletable || programContext === programContexts.RESCHEDULING) ?
          <CircleButton onClick={onOpenConfirmationDialog}>
            <img alt='trash icon' src={Trash} />
          </CircleButton> : null
        }
        <Typography className={classes.dialogHeading}>
          {isReadOnly ? 'Superset Details'
          : isUpdate ? 'Update Super Set' : 'Create Super Set'}
        </Typography>
        <Box className={classes.buttonsBox}>
          <Box style={{ paddingRight: 5 }}>
          {!isReadOnly ? <SaveButton
              saveState={saveState}
              onClick={onSavePressed}
            /> : null}
          </Box>
          <Link to={{ search: backLink() }}>
            <Box>
              <OvalButton onClick={resetSelection}>Close</OvalButton>
            </Box>
          </Link>
        </Box>
      </Box>
    );
  };

  const renderExercise = (exercise) => {
    if (Object.keys(exercise).length === 0) return null;
    return (
      <Link to={{ search: viewSuperSetExDetailsLink(exercise.id, superSetData.key)}}>
        <Box className={classes.recordContainer}>
          <Box className={classes.boxLayout}>
            <div className={classes.imageContainer}>
              <img
                width={75}
                height={48}
                alt="exercise-thumbnail"
                src={exercise.image_url || thumbnailPlaceholder}
                className={classes.thumbnail}
              />
              <ProgRegIconSet exercise={exercise} />
            </div>
            <div className={classes.nameContainer}>
              {exercise.name || "Exercise Name"}
            </div>
          </Box>
          <Box className={classes.arrowIconCont}>
            <img width={26} height={25} alt="arrow" src={arrowIcon} />
          </Box>
        </Box>
      </Link>
    );
  };

  return (
    <>
      <DialogContainer>
        <Box className={classes.inputContainer}>
          {renderButtonContainer()}
          <Typography className={classes.text}>Super Set Name</Typography>
          <UnderlineTextInput
            value={superSetName || ""}
            placeholder="Enter Super Set Name"
            onNameChanged={onNameChanged}
            readOnly={isReadOnly}
          />
          <Box className={classes.repsInput}>
            {!isReadOnly ? <Typography className={classes.repsText}>
              Enter a maximum of 10 Reps
            </Typography> : null}
            <LabelTextInput
              placeholder="10 rep(s)"
              label="Reps:"
              value={reps}
              onHandleChange={(e) => handleRepsInput(e)}
              onHandleBlur={(e) => {}}
              labelStyles={labelInputStyles}
              errorText="Enter a maximum of 10 Reps"
              showError={showError}
              fontFamily="Roboto, sans-serif"
              fontSize={14}
              width={512}
              height={40}
              isDisabled={isReadOnly || false}
            />
          </Box>
        </Box>
        <Box>
          {!isReadOnly && <Typography className={classes.chooseExerciseTxt}>
            Choose Exercises
          </Typography>}
          <Box className={classes.addExerciseContainer}>
            <Typography className={classes.addExerciseTxt}>
              {!isUpdate && 'Add'} Exercise 1
            </Typography>
            <Box onClick={() => onOpenChooseExerciseDialog(0)}>
              {!isReadOnly && (
                Object.keys(selectedExercises[0].exercise).length === 0 ? (
                  <img src={iconAdd} alt="add icon" className={classes.addIcon} />
                ) : (
                  <img src={editIcon} alt="edit icon" className={classes.addIcon} />
                )
              )}
            </Box>
          </Box>
          {renderExercise(selectedExercises[0].exercise)}
          <Box className={classes.addExerciseContainer}>
            <Typography className={classes.addExerciseTxt}>
              {!isUpdate && 'Add'} Exercise 2
            </Typography>
            <Box onClick={() => onOpenChooseExerciseDialog(1)}>
              {!isReadOnly && (
                Object.keys(selectedExercises[1].exercise).length === 0 ? (
                  <img src={iconAdd} alt="add icon" className={classes.addIcon} />
                ) : (
                  <img src={editIcon} alt="edit icon" className={classes.addIcon} />
                )
              )}
            </Box>
          </Box>
          {renderExercise(selectedExercises[1].exercise)}
        </Box>
      </DialogContainer>
      {renderChooseExercise()}
      <ConfirmDialog
        title='Remove Superset'
        actionButtonTitle='Delete'
        description={`Are you sure you would like to remove "${workoutExercise?.title ?? 'this superset'}"?`}
        open={showConfirmDeleteDialog}
        onClose={onCloseConfirmationDialog}
        handleConfirmAction={onPressDelete}
      />
    </>
  );
}

export default CreateSuperSet;
