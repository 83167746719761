// Libraries
import React, { useEffect, useCallback } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
// Material UI
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Local
import configureAppStore from './Store';
import Router from './Router';
import MobileLanding from './pages/MobileLanding';
import { colors } from './styles';
import { configureRequest } from './api/nasm_sdk_v2/request';
import {loginSuccess, logout} from './reducers/currentUserReducer';
import SessionTimeout from './components/SessionTimeout';
import { Maintenance } from './pages';
import Cookies from 'js-cookie';
import moment from 'moment';
import {STORAGE_KEYS} from './api/nasm_sdk_v2/constants';

// Side effects
import './app.css';
import { mobileRoutes } from './constants';
import InfoBanner from './components/InfoBanner';

// Constants
const { store, persistor } = configureAppStore();
configureRequest(() => store.dispatch(logout()));
const announcement = process.env.REACT_APP_ANNOUNCEMENT;

const theme = createMuiTheme({
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      light: colors.lightBlue,
      main: colors.mediumBlue,
      dark: colors.navy,
    },
    secondary: {
      main: colors.yellow_button_style,
    },
  },
  typography: {
    fontFamily: 'DMSans, sans-serif',
  },
});

const MIN_SCREEN_WIDTH = 1024;
const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const CLUB_CONNECT_TOKEN_COOKIE = '__edge_tk';

const RenderContent = () => {
  const matches = useMediaQuery(`(max-width: ${MIN_SCREEN_WIDTH}px)`);
  return (matches && !mobileRoutes.includes(window.location.pathname)) ? <MobileLanding /> : <Router />;
};

const App = () => {
  const getCookie = () => {
    return Cookies.get(CLUB_CONNECT_TOKEN_COOKIE);
  };

  const storeTokenData = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const removeCookie = () => {
    Cookies.remove(CLUB_CONNECT_TOKEN_COOKIE, { path: '/', domain: '.nasm.org' });
  };

  const getClubConnectToken = useCallback(() => {
    const cookieValue = getCookie();
    if (cookieValue) {
      const tokens = JSON.parse(cookieValue);
      const newTokenInfo = {
        access_token: tokens.access_token.token,
        refresh_token: tokens.refresh_token.token,
        token_type: 'Bearer',
        cc_manager: tokens.scope === 'read-only',
        expires_in: moment()
            .add(tokens.access_token.expires_in, 'seconds')
            .toISOString(),
      };
      storeTokenData(STORAGE_KEYS.AUTH_TOKEN, newTokenInfo);
      removeCookie();
      store.dispatch(loginSuccess());
    }
  }, []);

  useEffect( () => {
    getClubConnectToken();
  }, [getClubConnectToken]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SessionTimeout/>
          {!!announcement && <InfoBanner
            text={announcement}
          />}
          <BrowserRouter>
            { isMaintenanceMode
              ? <Maintenance />
              : <RenderContent />
            }
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
