import React, { useState, useReducer, useEffect } from 'react';
import { Redirect, Route, useHistory, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import querystring from 'query-string';
import { Box } from '@material-ui/core';

// Local
import ProgramListPanel from '../../components/Panels/ProgramListPanel';
import ProgramDetailsPanel from '../../components/Panels/ProgramDetailsPanel';
import WorkoutSectionExerciseContext from '../../components/WorkoutSectionExerciseContext';
import ExerciseDetailsPanel from '../../components/Panels/ExerciseDetailsPanel';
import EditExercisePanel from '../../components/Panels/EditExercisePanel';
import ProgramDragDropContext from '../../components/Programs/ProgramDragDropContext';

import PanelContainer from '../../components/Panels/PanelContainer';
import { PageHeader, PageToolBar } from '../../components';

import { clearWorkout } from '../../reducers/selectedWorkoutReducer';
import { clearProgram, createNewProgram } from '../../reducers/selectedProgramReducer';
import { programLibrary } from '../../reducers/programContextReducer';
import { colors } from '../../styles';

import {
  initReducer,
  WORKOUT_BUTTONS_VISIBILITY_ACTIONS,
  workoutButtonsVisibilityReducer,
} from '../../reducers/local/workoutButtonsVisibilityReducer';
import { FloatingCircleButton } from '../../components/Buttons';
import AddSuperSetsPanel from '../../components/Panels/AddSuperSetsPanel';
import AddCircuitsPanel from '../../components/Panels/AddCircuitsPanel';
import NoticeBar from '../../components/Notice/NoticeBar';

const views = {
  programList: {
    key: 'programList',
    programList: true,
  },
  programDetails: {
    key: 'programDetails',
    programList: true,
    programDetails: true,
  },
  addWorkouts: {
    key: 'addWorkouts',
    programDetails: true,
    addWorkouts: true,
  },
  workoutDetails: {
    key: 'workoutDetails',
    workoutDetails: true,
    programDetails: true,
  },
  addExercises: {
    key: 'addExercises',
    workoutDetails: true,
    addExercises: true,
  },
  editExercise: {
    key: 'editExercise',
    workoutDetails: true,
    editExercise: true,
  },
  viewExercise: {
    key: 'viewExercise',
    addExercises: true,
    viewExercise: true,
    // can be viewed together with viewExercise
    // when selecting an exercise from an NASM Program
    workoutDetails: false,
  },
  superSetDetails: {
    key: 'superSetDetails',
    workoutDetails: true,
    superSetDetails: true,
  },
  superSetExerciseDetails: {
    key: 'superSetExerciseDetails',
    superSetDetails: true,
    viewExercise: true,
    workoutList: false,
  },
  circuitDetails: {
    key: 'circuitDetails',
    workoutDetails: true,
    circuitDetails: true,
  },
  circuitExerciseDetails: {
    key: 'circuitExerciseDetails',
    circuitDetails: true,
    viewExercise: true,
    workoutList: false,
  },
  progressionRegressionExDetails: {
    key: 'progressionRegressionExDetails',
    progressionRegressionExDetails: true,
    viewExercise: true,
    workoutDetails: true,
  },
};

export default function Programs(props) {
  const userType = JSON.parse(localStorage.getItem("AUTH_TOKEN"));
  const history = useHistory();
  const location = history.location;
  const dispatch = useDispatch();
  const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const view = query?.view || 'programList';
  const { programId } = query;
  const { sectionIds } = props;
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);

  const [selectedProgramIndex, setSelectedProgramIndex] = useState(-1);
  const [superSetData, setSuperSetData] = useState({});
  const [circuitData, setCircuitsData] = useState({});
  const currentTab = location.pathname.split('/').pop();

  const resetProgramIndex = () => {
    setSelectedProgramIndex(-1);
    dispatch(clearProgram());
  };

  const [workoutButtonsVisibilityFlags, localDispatch] =
    useReducer(workoutButtonsVisibilityReducer, {}, initReducer);

  function updateButtonVisibilityFlags(
    {
      isCopyButtonVisible,
      isSaveButtonVisible,
      isAddExercisesButtonVisible,
      isAddButtonVisible,
    },
  ) {
    localDispatch({
      type: WORKOUT_BUTTONS_VISIBILITY_ACTIONS.UPDATE_BUTTON_VISIBILITY_FLAGS,
      payload: {
        showCopyButton: isCopyButtonVisible,
        showSaveButton: isSaveButtonVisible,
        showAddExercisesButton: isAddExercisesButtonVisible,
        showAddButton: isAddButtonVisible,
      },
    });
  }

  useEffect(() => {
    // Reset highlighted program when
    // switching between My/NASM Program tabs
    setSelectedProgramIndex(-1);
  }, [location.pathname]);

  useEffect(() => {
    updateButtonVisibilityFlags({
      isCopyButtonVisible: false,
      isSaveButtonVisible: location.pathname.includes('my-programs'),
      isAddExercisesButtonVisible: location.pathname.includes('my-programs'),
      isAddButtonVisible: location.search.includes('addWorkouts'),
    });
  }, [location]);

  useEffect(() => {
    dispatch(programLibrary());
  }, [dispatch, location]);

  const onCreateProgram = () => {
    // clear selected workout and program to prevent
    // unintended side effects that occur in reducer functions such as saveWorkout()
    dispatch(clearWorkout());
    dispatch(clearProgram());
    dispatch(createNewProgram());
  };

  const onCreateProgramAndUpdateNavigation = () => {
    history.push({
      pathname: '/libraries/programs/my-programs',
      search: '?view=programDetails&programId=new',
    });
    onCreateProgram();
  };

  // NAVIGATOR HELPER FUNCTIONS //
  const getProgramUrl = (programId) => {
    const query = updateQuery(location.search, { view: views.programDetails.key, programId });
    return `${location.pathname}?${query}`;
  };

  const getCurrentProgram = () => {
    const query = updateQuery(location.search, { view: views.programDetails.key, programId });
    return `${location.pathname}?${query}`;
  };

  const getWorkoutUrlParams = (workoutId) => {
    const query = updateQuery(location.search, { view: views.workoutDetails.key, workoutId: workoutId });
    return {
      pathname: location.pathname,
      search: query,
    };
  };

  const programDetailsLink = () => {
    const query = updateQuery(location.search, { view: views.programDetails.key, programId });
    return `${location.pathname}?${query}`;
  };

  const addExercisesListLink = () => {
    return updateQuery(location.search, { view: views.addExercises.key, exerciseId: undefined });
  };

  const copyWorkoutLink = () => {
    const query = updateQuery(location.search, { view: views.workoutDetails.key, workoutId: undefined });
    return `${location.pathname}?${query}`;
  };

  const copyProgramLink = (programId) => {
    const query = updateQuery(location.search, { view: views.programDetails.key, programId });
    return `/libraries/programs/my-programs?${query}`;
  };

  const editExerciseLink = () => {
    return updateQuery(location.search, { view: views.editExercise.key });
  };

  const viewExerciseLink = (exerciseId, toggleWorkoutDetailsVisibility = false) => {
    // toggle secondary panel on the right-hand side to view
    views.viewExercise.workoutDetails = toggleWorkoutDetailsVisibility;
    views.viewExercise.addExercises = !toggleWorkoutDetailsVisibility;

    return updateQuery(location.search, {
      view: views.viewExercise.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: true,
    });
  };

  const viewWorkoutDetailsLink = () => {
    return updateQuery(location.search, { view: views.workoutDetails.key, exerciseId: undefined });
  };

  const addWorkoutLink = () => {
    return updateQuery(location.search, { view: views.addWorkouts.key, workoutId: undefined });
  };

  const viewSuperSetsLink = () => {
    if(views[view].addExercises){
      views.superSetDetails.workoutDetails = false;
      views.superSetDetails.addExercises = true;
    } else {
      views.superSetDetails.workoutDetails = true;
      views.superSetDetails.addExercises = false;
    }
    return updateQuery(location.search, { view: views.superSetDetails.key });
  };

  const viewSuperSetExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(location.search, {
      exerciseId: exerciseId,
      view: views.superSetExerciseDetails.key,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewCircuitLink = () => {
    if(views[view].addExercises){
      views.circuitDetails.workoutDetails = false;
      views.circuitDetails.addExercises = true;
    }  else {
      views.circuitDetails.workoutDetails = true;
      views.circuitDetails.addExercises = false;
    }
    return updateQuery(location.search, { view: views.circuitDetails.key });
  };

  const viewCircuitExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    return updateQuery(location.search, {
      exerciseId: exerciseId,
      view: views.circuitExerciseDetails.key,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  const viewProgressionRegressionExDetailsLink = (exerciseId, showProgressionsRegressions = true) => {
    if(currentTab !== 'nasm-programs' && views[view].viewExercise){
      views.progressionRegressionExDetails.workoutDetails = false;
      views.progressionRegressionExDetails.addExercises = true;
    }  else {
      views.progressionRegressionExDetails.workoutDetails = true;
      views.progressionRegressionExDetails.addExercises = false;
    }
    return updateQuery(location.search, {
      view: views.progressionRegressionExDetails.key,
      exerciseId: exerciseId,
      showProgressionsRegressions: showProgressionsRegressions,
    });
  };

  // END NAVIGATOR HELPER FUNCTIONS //

  return (
    <>
      <PageHeader title="Programs" />
      <PageToolBar
        linkOptions={[
          {
            label: "My Programs",
            to: "/libraries/programs/my-programs",
          },
          {
            label: "NASM Programs",
            to: "/libraries/programs/nasm-programs",
          },
        ]}
        color={selectedProfile?.ClubId ? colors.graphite : colors.lightBlue}
      />
      <FloatingCircleButton
        disabled={
          ![views.programList.key].includes(view) ||
          (location.pathname.includes("my-programs") &&
            view === views.programDetails.key)
        }
        onClick={onCreateProgramAndUpdateNavigation}
      />
      <Box>
        {userType?.cc_manager && (
          <Box style={{ padding: "20px 90px 0px 90px" }}>
            <NoticeBar />
          </Box>
        )}
        <PanelContainer>
          <Switch>
            <Route exact path="/libraries/programs">
              <Redirect to={{ pathname: "/libraries/programs/my-programs" }} />
            </Route>

            {/* MY PROGRAMS */}
            <Route to="/libraries/programs/my-programs">
              <ProgramListPanel
                visible={!!views[view].programList}
                getProgramUrl={getProgramUrl}
                selectedProgramIndex={selectedProgramIndex}
                setSelectedProgramIndex={setSelectedProgramIndex}
                resetProgramIndex={resetProgramIndex}
                copyProgramLink={copyProgramLink}
                onCreateProgram={onCreateProgram}
                ftuTo="/libraries/programs/my-programs/?view=programDetails&programId=new"
                ftuBtnHidden={!!views[view].programDetails}
              />
              <ProgramDragDropContext
                programDetailsVisible={!!views[view].programDetails}
                addWorkoutsVisible={!!views[view].addWorkouts}
                currentView={view}
                programId={programId}
                getWorkoutUrlParams={getWorkoutUrlParams}
                programDetailsKey={views.programDetails.key}
                addWorkoutKey={views.addWorkouts.key}
                resetProgramIndex={resetProgramIndex}
                viewWorkoutDetailsLink={getWorkoutUrlParams}
                programDetailsLink={programDetailsLink}
                copyProgramLink={copyProgramLink}
              />
              <WorkoutSectionExerciseContext
                isWorkoutSectionsVisible={!!views[view].workoutDetails}
                isAddExercisesVisible={!!views[view].addExercises}
                sectionIds={sectionIds}
                // Navigation
                currentView={view}
                addExercisesKey={views.addExercises.key}
                addExercisesListLink={addExercisesListLink}
                backLink={
                  views.workoutDetails.addWorkouts
                    ? addWorkoutLink
                    : getCurrentProgram
                }
                copyWorkoutLink={copyWorkoutLink}
                editExerciseLink={editExerciseLink}
                viewWorkoutDetailsLink={viewWorkoutDetailsLink}
                viewExerciseLink={viewExerciseLink}
                resetWorkoutIndex={() => dispatch(clearWorkout())}
                selectedExerciseDraggableId={-1}
                setSelectedExerciseDraggableId={() => {}}
                resetSelectedExercise={() => {}}
                workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
                updateButtonVisibilityFlags={updateButtonVisibilityFlags}
                viewSuperSetsLink={viewSuperSetsLink}
                setSuperSetData={setSuperSetData}
                viewCircuitLink={viewCircuitLink}
                setCircuitsData={setCircuitsData}
              />
              <AddSuperSetsPanel
                visible={!!views[view].superSetDetails}
                resetSelection={() => {}}
                backLink={
                  views[view].addExercises
                    ? addExercisesListLink
                    : viewWorkoutDetailsLink
                }
                superSetData={superSetData}
                setSuperSetData={setSuperSetData}
                viewSuperSetExDetailsLink={viewSuperSetExDetailsLink}
              />
              <AddCircuitsPanel
                visible={!!views[view].circuitDetails}
                resetSelection={() => {}}
                backLink={
                  views[view].addExercises
                    ? addExercisesListLink
                    : viewWorkoutDetailsLink
                }
                circuitData={circuitData}
                setCircuitsData={setCircuitsData}
                viewCircuitExDetailsLink={viewCircuitExDetailsLink}
              />
              <EditExercisePanel
                backLink={viewWorkoutDetailsLink}
                visible={!!views[view].editExercise}
                // This will allow the trainer to save the entire workout that contains the exercise
                // in a program without needing to press save again in the Workout Sections Panel
                isAutoSaveEnabled
                viewExerciseDetailsLink={viewProgressionRegressionExDetailsLink}
              />
              <ExerciseDetailsPanel
                visible={!!views[view].viewExercise}
                backLink={
                  views[view].superSetDetails
                    ? viewSuperSetsLink
                    : views[view].circuitDetails
                    ? viewCircuitLink
                    : views[view].progressionRegressionExDetails
                    ? viewWorkoutDetailsLink
                    : views.viewExercise.workoutDetails
                    ? viewWorkoutDetailsLink
                    : addExercisesListLink
                }
                resetSelection={() => {}}
                isAutoSaveEnabled={
                  workoutButtonsVisibilityFlags.isAutoSaveEnabled
                }
                hideAddBtn={
                  views[view].superSetDetails ||
                  views[view].circuitDetails ||
                  views[view].progressionRegressionExDetails
                    ? true
                    : false
                }
                viewExerciseDetailsLink={
                  views[view].superSetDetails
                    ? viewSuperSetExDetailsLink
                    : views[view].circuitDetails
                    ? viewCircuitExDetailsLink
                    : viewProgressionRegressionExDetailsLink
                }
              />
            </Route>

            {/* NASM PROGRAMS */}
            <Route to="/libraries/programs/nasm-programs">
              <ProgramListPanel
                visible={!!views[view].programList}
                getProgramUrl={getProgramUrl}
                selectedProgramIndex={selectedProgramIndex}
                setSelectedProgramIndex={setSelectedProgramIndex}
                resetProgramIndex={resetProgramIndex}
              />
              <ProgramDetailsPanel
                visible={!!views[view].programDetails}
                programListKey={views.programList.key}
                programId={programId}
                resetProgramIndex={resetProgramIndex}
                getWorkoutUrlParams={getWorkoutUrlParams}
              />
            </Route>
          </Switch>
        </PanelContainer>
      </Box>
    </>
  );
}

// Helper functions
function updateQuery(search, next) {
  const prev = querystring.parse(search, { parseBooleans: true });
  return querystring.stringify({
    ...prev,
    ...next,
  });
}
