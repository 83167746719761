import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  panelContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: '30px 90px 30px 90px',
    // Only apply to any div after the first div in this container
    '& > div + div': {
      marginLeft: 20,
    },
  },
});

export default function PanelContainer (props) {
  const classes = useStyles();
  return (
    <div className={classes.panelContainer}>
      {props.children}
    </div>
  );
}
