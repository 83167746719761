import React, { useState, useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import {ClearOptions, HighlightSearchBar} from '../Inputs';
import { OvalButton } from '../Buttons';

import ExerciseScheduleList from './ExerciseScheduleList';
import FilterMenu from './FilterMenu';

import { colors } from '../../styles';

import useFetchExerciseFilters from '../../hooks/exercises/FetchExerciseFilters';
import useFetchFitnessComponentsList from '../../hooks/FetchFitnessComponentsList';
import useFetchMuscleGroupsList from '../../hooks/FetchMuscleGroupsList';
import nasmApi from '../../api/endpoints';
import { deSelectExercise } from '../../reducers/selectedWorkoutReducer';
import useFetchExercisesList from '../../hooks/exercises/FetchExercisesList';

const exerciseScheduleActions = Object.freeze({
  CLEAR: 'clear',
  TOGGLE_EXERCISE_ID_CHECKED_FLAG: 'toggleExerciseIdCheckedFlag',
});

function exerciseScheduleReducer (state, action) {
  const payload = action.payload;
  const ACTIONS = Object.freeze({ ...exerciseScheduleActions });

  if (action.type === ACTIONS.CLEAR) {
    return {};
  } else if (action.type === ACTIONS.TOGGLE_EXERCISE_ID_CHECKED_FLAG) {
    const newState = {
      ...state,
      [payload.exerciseId]: payload.isChecked,
    };

    payload.toggleButtonsVisibility(newState);

    return newState;
  }

  return { ...state };
}

const useStyles = makeStyles({
  root: {
    height: '688px',
    margin: '30px 90px 2px',
    padding: '20px 0 0',
    borderRadius: '6px',
    boxShadow: '0 -1px 6px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #e6e6e6',
    backgroundColor: colors.white,
  },
  controlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchBarPadding: {
    marginLeft: 26,
    marginRight: 12,
  },
  clearAllPadding: {
    marginRight: 33,
    marginLeft: 14,
  },
});

export default function ExerciseScheduleListContainer (props) {
  const {selectedExercises, setSelectedExercises, onPrepareWorkouts, clientFirstName } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {selectedWorkout , workouts} = useSelector(state => state);
  const defaultSections = workouts.defaultSections;

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState('');

  const [sourceFilters, setSourceFilters] = useState([]);
  const [muscleGroupFilters, setMuscleGroupFilters] = useState([]);
  const [fitnessComponentFilters, setFitnessComponentFilters] = useState([]);

  const [pendingSourceFilters, setPendingSourceFilters] = useState([]);
  const [pendingMuscleGroupFilters, setPendingMuscleGroupFilters] = useState([]);
  const [pendingFitnessComponentFilters, setPendingFitnessComponentFilters] = useState([]);

  // This may need to move up a component so that ScheduleDialog can reference
  // May need to change into an array of exercise objects
  // exerciseCheckFlags - keeps reference to which exercises are checked by the trainer
  // key - exerciseId | value - boolean
  const [exerciseCheckFlags, localDispatch] = useReducer(exerciseScheduleReducer, {}, () => ({}));
  const [areButtonsVisible, setVisible] = useState(false);

  const { exerciseFilters } = useFetchExerciseFilters();
  const { muscleGroups } = useFetchMuscleGroupsList();
  const { fitnessComponents } = useFetchFitnessComponentsList();
  const [supersetPageNumber, setSupersetPageNumber] = useState(1);
  const [hasMoreSuperset, setHasMoreSuperset] = useState(true);
  const [isSuperSetLoading, setIsSuperSetLoading] = useState(false);
  const [compoundRoutines, setCompoundRoutines] = useState([]);

  const {
    loading,
    error,
    exerciseData,
    hasMore,
  } = useFetchExercisesList({
    searchText: searchText,
    pageNumber: pageNumber,
    sizePerPage: 30,
    exercisesFilter: sourceFilters.join(','),
    muscleGroupIds: muscleGroupFilters.join(','),
    fitnessComponentIds: fitnessComponentFilters.join(','),
    shouldFetch: !hasMoreSuperset,
  });
  
  useEffect(() => {
     fetchAllSuperSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, supersetPageNumber]);

  useEffect(() => {
    if (Object.keys(selectedWorkout?.entities?.exercises).length > 0) {
      if (selectedWorkout?.entities?.exercises && Object.keys(selectedExercises).length === 0) {
        setSelectedExercises(selectedWorkout?.entities?.exercises);
      }
      let selectedWorkoutValues = Object.values(selectedWorkout?.entities?.exercises);
      for (const exe of selectedWorkoutValues) {
        const exercise = [...compoundRoutines, ...exerciseData].find(ex => ex.id === exe.id);
        if (exercise) {
            toggleExerciseCheckedFlag(exercise.id);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkout?.entities?.exercises, compoundRoutines, exerciseData]);

  const toggleExerciseCheckedFlag = (exerciseId) => {
    localDispatch({
        type: exerciseScheduleActions.TOGGLE_EXERCISE_ID_CHECKED_FLAG,
        payload: {
            exerciseId,
            isChecked: true,
            toggleButtonsVisibility: (checkFlagsMap) => {
                const checkFlags = Object.values(checkFlagsMap);
                setVisible(checkFlags.includes(true));
            },
        },
    });
};

  const fetchAllSuperSet = async () => {
    const jsonData = {
      page: supersetPageNumber,
      size: 10,
      search: searchText,
    };
    if(hasMoreSuperset) {
      setIsSuperSetLoading(true);
      try {
        const res = await nasmApi.supersets.fetchAllSuperSets(jsonData);
        if (res) {
          setHasMoreSuperset(res?.result?.hasMore);
          setCompoundRoutines((prevState) => ( jsonData.page === 1
            ? [...res?.result?.compoundRoutines]
            : [...prevState, ...res?.result?.compoundRoutines]));
          setIsSuperSetLoading(false);
        }
      } catch (error) {
        alert(error?.message || 'Something went wrong! Please try again later.');
      }
    }
  };

  const activeFilterCount = sourceFilters.length + muscleGroupFilters.length + fitnessComponentFilters.length;

  const clearSelectedExercises = () => {
    setVisible(false);
    localDispatch({ type: exerciseScheduleActions.CLEAR });
    setSelectedExercises({});
  };

  const onNext = () => {
    onPrepareWorkouts();
    const clientName = clientFirstName?.toLowerCase() ?? ':clientFirstName';
    history.push(
      `/clients/my-clients/${clientName}/dashboard/calendar/schedule-exercises-schedule`,
    );
  };

  function filterExerciseById(data, targetId) {
    for (const key in data) {
        if (data[key].id === targetId) {
            const result = {};
            result[key] = data[key];
            return result;
        }
    }
    return {};
  }

  const onToggleExerciseCheckmark = ({ checkFlag, exercise }) => {
    if (checkFlag) {
      setSelectedExercises(prevState => ({
        ...prevState,
        [exercise.id]: exercise,
      }));
    } else {
      setSelectedExercises(prevState => {
        const newState = { ...prevState };
        const selectedExArr = Object.values(newState);
        const selectedExKeys = Object.keys(newState);
        const selectedExIndex = selectedExArr.findIndex((ex) => ex.id === exercise.id);
        if (selectedExIndex > -1) {
          const selectedExKey = selectedExKeys[selectedExIndex];
          delete newState[selectedExKey];
        }
        return newState;
      });

      // deSelectExercise from redux
      const defaultSection = defaultSections.find((s) => s.name === "Warm-Up");
      if (defaultSection) {
        const filteredExerciseData = filterExerciseById(selectedWorkout.entities.exercises, exercise.id);
        dispatch(
          deSelectExercise({
            exercise: filteredExerciseData,
            sectionId: defaultSection.id,
          }),
        );
      } else {
        console.error("Could Not find a default section to save the exercises under");
      }
    }

    localDispatch({
      type: exerciseScheduleActions.TOGGLE_EXERCISE_ID_CHECKED_FLAG,
      payload: {
        exerciseId: exercise.id,
        isChecked: checkFlag,
        // callback function that contains the most up-to-date reducer state (checkFlagsMap)
        toggleButtonsVisibility: (checkFlagsMap) => {
          const checkFlags = Object.values(checkFlagsMap);
          // if at least 1 exercise is checked, show Schedule and Clear All buttons
          // otherwise, set the buttons visibility to false
          setVisible(checkFlags.includes(true));
        },
      },
    });
  };

  function resetSuperSetData () {
    setSupersetPageNumber(1);
    setHasMoreSuperset(true);
    setCompoundRoutines([]);
  }

  function resetPageNumber () {
    setPageNumber(1);
  }

  function handleSearch (e) {
    setSearchText(e.target.value);
    resetPageNumber();
    resetSuperSetData();
  }

  const clearAllFilters = () => {
    setSourceFilters([]);
    setMuscleGroupFilters([]);
    setFitnessComponentFilters([]);
    setPendingSourceFilters([]);
    setPendingMuscleGroupFilters([]);
    setPendingFitnessComponentFilters([]);
    resetPageNumber();
  };

  const handleFilters = (id, updateActiveFilters) => {
    updateActiveFilters(prevState => {
      const filtersCopy = [...prevState];
      const idIndex = filtersCopy.indexOf(id);

      // remove from filters array if id exists
      if (idIndex !== -1) {
        filtersCopy.splice(idIndex, 1);
      } else {
        // add id to filters array
        filtersCopy.push(id);
      }

      return [...(new Set([...filtersCopy]))];
    });

    resetPageNumber();
  };

  const handleSourceFilters = (filterValue) => {
    handleFilters(filterValue, setPendingSourceFilters);
  };

  const handleMuscleGroupFilters = (id) => {
    handleFilters(id, setPendingMuscleGroupFilters);
  };

  const handleFitnessComponentFilters = (id) => {
    handleFilters(id, setPendingFitnessComponentFilters);
  };

  const onClearSearch = () => {
    setSearchText('');
    resetPageNumber();
    resetSuperSetData();
  };

  const onCloseFilter = () => {
    setSourceFilters(pendingSourceFilters);
    setFitnessComponentFilters(pendingFitnessComponentFilters);
    setMuscleGroupFilters(pendingMuscleGroupFilters);
    setPageNumber(1);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.controlContainer}>
        <Box className={classes.inputContainer}>
          <Box className={classes.searchBarPadding}>
            <HighlightSearchBar
              isClearable={searchText.length > 0}
              value={searchText}
              onChange={handleSearch}
              onClickClear={onClearSearch}
            />
          </Box>
          <FilterMenu
            // List of all possible filters that can be applied
            sources={exerciseFilters}
            muscleGroups={muscleGroups}
            fitnessComponents={fitnessComponents}
            // ------ //
            // List of all active filters
            sourceFilters={pendingSourceFilters}
            muscleGroupFilters={pendingMuscleGroupFilters}
            fitnessComponentFilters={pendingFitnessComponentFilters}
            // ----- //
            handleSourceFilters={handleSourceFilters}
            handleMuscleGroupFilters={handleMuscleGroupFilters}
            handleFitnessComponentFilters={handleFitnessComponentFilters}
            activeFilterCount={activeFilterCount}
            clearAllFilters={clearAllFilters}
            onClose={onCloseFilter}
          />
          <ClearOptions
            text='Clear Filters'
            margin='0 0 0 12px'
            onClick={clearAllFilters}
            visible={activeFilterCount > 0}
          />
        </Box>
        {areButtonsVisible &&
          <Box className={classes.inputContainer}>
            <OvalButton
              border='none'
              width={95}
              fontWeight='bold'
              backgroundcolor={colors.yellow_button_style}
              color={colors.white}
              onClick={onNext}
            >
              Next
            </OvalButton>
            <Box className={classes.clearAllPadding}>
              <OvalButton
                width={82}
                onClick={clearSelectedExercises}
              >
                Clear All
              </OvalButton>
            </Box>
          </Box>}
      </Box>
      <ExerciseScheduleList
        searchText={searchText}
        loading={loading}
        hasMore={hasMore}
        exerciseData={exerciseData}
        error={error}
        exerciseCheckFlags={exerciseCheckFlags}
        onToggleCheckmark={onToggleExerciseCheckmark}
        setPageNumber={setPageNumber}
        activeFilterCount={activeFilterCount}
        setSupersetPageNumber={setSupersetPageNumber}
        hasMoreSuperset={hasMoreSuperset}
        isSuperSetLoading={isSuperSetLoading}
        compoundRoutines={compoundRoutines}
      />
    </Box>
  );
}
