import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import placeholderIcon from "../../resources/user-dashboard-client-profile-with-no-photo@2x.png";
import settingsIcon from "../../resources/settings.svg";
import arrowDropdown from "../../resources/btn-arrow-expand.svg";
import closeCircle from "../../resources/close-circle.svg";
import arrowRight from "../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg";
import AccountSettings from "../Dialogs/AccountSettings";
import { logout } from "../../reducers/currentUserReducer";
import { FEATURE_FLAGS, VERSION_NUMBER } from "../../constants";
import { UA_PERMISSION_TYPES } from "../../constants";
import { colors } from "../../styles";
import { handleProfileClick } from "../../reducers/clubConnectReducer";

const useStyles = makeStyles(() => ({
  dropdownHeader: {
    width: "100%",
    minHeight: 54,
    paddingTop: 8,
    paddingLeft: 13,
    paddingRight: 39,
    border: "none",
    backgroundColor: colors.subtle_grey,
    "&:focus": {
      outline: "none",
    },
  },
  headerInfo: {
    display: "flex",
    alignItems: "center",
  },
  profileAvatar: {
    height: "38px",
    width: "38px",
    borderRadius: "19px",
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.grey_text_color,
    objectFit: "cover",
  },
  profileAvatarBorder: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "44px",
    width: "44px",
    border: `${props.selected ? "4px" : 0} solid rgb(255, 255, 255)`,
    borderRadius: "22px",
    "&:hover": {
      borderWidth: "4px",
    },
  }),
  fullName: {
    fontSize: "14px",
  },
  email: {
    fontSize: "9px",
    color: colors.steel,
  },
  menu: {
    width: 230,
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: colors.selected_highlight_copy_6,
    },
    fontSize: "15px",
    height: "42px",
  },
  version: {
    fontSize: "9px",
    color: colors.steel,
    marginTop: "2px",
    marginLeft: "2px",
  },
  popoverPaper: {
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.38)",
    borderRadius: "6px",
    width: "230px",
    border: `1px solid ${colors.selected_highlight_copy_6}`,
  },
  permissionText: {
    fontSize: "9px",
    color: colors.steel,
    margin: "4px 0",
  },
  upgrade: {
    fontSize: "12px",
    color: colors.radio_blue,
    textDecoration: "underline",
    marginBottom: "8px",
    cursor: "pointer",
  },
  profileMenu: {
    width: 363,
    paddingTop: 0,
    paddingBottom: 0,
  },
  profilePopoverPaper: {
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.38)",
    borderRadius: "6px",
    width: "363px",
    border: `1px solid ${colors.selected_highlight_copy_6}`,
  },
  profileDropdownIcon: {
    height: "26px",
    width: "26px",
    objectFit: "cover",
  },
  profileHeaderInfo: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    paddingTop: 8,
    paddingLeft: 13,
    paddingRight: 10,
  },
  profileHeaderTitle: {
    fontSize: "24px",
    fontWeight: "700",
    margin: "10px 0px 15px 10px",
  },
  profileClose: {
    height: "26px",
    width: "26px",
    objectFit: "cover",
  },
  profileItemContainer: {
    maxHeight: "500px",
    overflow: "auto",
  },
  profileMenuItem: {
    "&:hover": {
      backgroundColor: colors.lightest_blue,
    },
    fontSize: "15px",
    height: "105px",
    borderBottom: `2px solid ${colors.subtle_grey}`,
  },
  profileListAvatar: {
    height: "60px",
    width: "60px",
    borderRadius: "60px",
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.grey_text_color,
    objectFit: "cover",
    marginLeft: "10px",
  },
  profileListRightArrow: {
    height: "26px",
    width: "26px",
    objectFit: "cover",
    marginRight: "10px",
  },
  profileListName: {
    fontSize: "17px",
    fontWeight: 500,
    marginLeft: "10px",
  },
  profileListDescription: {
    fontSize: "13px",
    color: colors.steel,
    marginLeft: "10px",
  },
}));

function UpgradeText({ subscription, onClickUpgrade }) {
  const classes = useStyles();
  if (subscription !== UA_PERMISSION_TYPES.EDGE_TRAINER_PRO) {
    const subType =
      subscription === UA_PERMISSION_TYPES.EDGE_TRAINER_PLUS ? "Plus" : "Base";
    return (
      <Box>
        <Typography
          className={classes.permissionText}
        >{`Your account is using EDGE ${subType}`}</Typography>
        <Typography className={classes.upgrade} onClick={onClickUpgrade}>
          Upgrade
        </Typography>
      </Box>
    );
  }
  return null;
}

export default function NavSettingsItem({
  routes,
  activeRoute,
  basicUserInfo,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);
  const [openProfileDropdown, setOpenProfileDropdown] = useState(null);
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const classes = useStyles({ selected: anchorEl });
  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));


  const profilesData = [
    {
      ClubId: null,
      ClubName: "Personal",
      LocationName: [],
      ClubLogoUrl: basicUserInfo.avatar_url,
    },
  ];

  const clubs = basicUserInfo?.club_connect_user
    ? Array.isArray(basicUserInfo?.club_connect_user)
      ? basicUserInfo?.club_connect_user
      : Object.values(basicUserInfo?.club_connect_user)
    : [];

  if (clubs?.length) {
    clubs.forEach((club) => {
      if (
        club.ClubStatus?.toLowerCase() === "active" &&
        club.TrainerStatus?.toLowerCase() === "active"
      ) {
        if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED) {
          // eslint-disable-next-line no-unused-expressions
          club.Locations?.forEach((loc) => {
            const clubObj = {
              ...club,
              Locations: {
                ...loc,
              },
            };
            profilesData.push(clubObj);
          });
        } else {
          profilesData.push(club);
        }
      }
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const onOpenAccountSettings = () => {
    setAnchorEl(null);
    setOpenProfileDropdown(null);
    setShowAccountSettings(true);
  };

  const onCloseAccountSettings = () => {
    setShowAccountSettings(false);
  };

  const onClickUpgrade = () => {
    setAnchorEl(null);
    setOpenProfileDropdown(null);
    history.replace("/clients/view-subscriptions");
  };

  const handleProfileDropdownClick = (event) => {
    setOpenProfileDropdown(event.currentTarget);
  };

  const handleProfileDropdownClose = () => {
    setOpenProfileDropdown(null);
  };

  const handleProfileItemClick = (profile) => {
    history.replace(!currentUser?.cc_manager ? `/clients/my-clients` : `/dashboard/cc-dashboard`);
    dispatch(handleProfileClick(profile));
    setOpenProfileDropdown(null);
    handleClose();
  };

  let profileUri = placeholderIcon;
  if (selectedProfile?.ClubId) {
    profileUri = selectedProfile?.ClubLogoUrl;
  } else if (basicUserInfo.avatar_url) {
    profileUri = basicUserInfo.avatar_url;
  }

  const renderSettingsIcon = () => (
    <IconButton onClick={onOpenAccountSettings} disabled={currentUser?.cc_manager}>
      <img
        src={settingsIcon}
        alt="settings"
        onError={(e) => {
          e.target.src = settingsIcon;
        }}
      />
    </IconButton>
  );

  const renderProfileIcon = () => {
    return (
      <IconButton
        aria-controls="nav-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        classes={{
          root: classes.menuRoot,
          label: classes.menuLabel,
        }}
        onClick={handleClick}
      >
        <Box>
          <img
            className={classes.profileAvatar}
            src={profileUri || placeholderIcon}
            alt="Profile"
            onError={(e) => {
              e.target.src = placeholderIcon;
            }}
          />
        </Box>
        {clubs?.length ? (
          <Box>
            <img
              className={classes.profileDropdownIcon}
              src={arrowDropdown}
              alt="Dropdown"
              onError={(e) => {
                e.target.src = arrowDropdown;
              }}
            />
          </Box>
        ) : null}
      </IconButton>
    );
  };

  const renderMyProfilesMenu = () => (
    <Menu
      id="nav-menu"
      anchorEl={openProfileDropdown}
      keepMounted
      open={Boolean(openProfileDropdown)}
      onClose={handleProfileDropdownClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      MenuListProps={{
        className: classes.profileMenu,
      }}
      PopoverClasses={{
        paper: classes.profilePopoverPaper,
      }}
    >
      <Box
        item
        border={0}
        component={Grid}
        boxShadow={0}
        className={classes.profileHeaderInfo}
      >
        <Typography className={classes.profileHeaderTitle}>
          My Profiles
        </Typography>
        <img
          className={classes.profileClose}
          src={closeCircle}
          alt="close"
          onError={(e) => {
            e.target.src = closeCircle;
          }}
          onClick={handleProfileDropdownClose}
        />
      </Box>
      <Box className={classes.profileItemContainer}>
        {profilesData.map((profile, index) => (
          <MenuItem
            key={index}
            className={classes.profileMenuItem}
            onClick={() => handleProfileItemClick(profile)}
          >
            <Box
              item
              xs={3}
              border={0}
              component={Grid}
              boxShadow={0}
              className={classes.headerInfo}
            >
              <img
                className={classes.profileListAvatar}
                src={profile?.ClubLogoUrl || placeholderIcon}
                alt="Avatar"
                onError={(e) => {
                  e.target.src = placeholderIcon;
                }}
              />
            </Box>
            <Box
              item
              xs={8}
              component={Grid}
              style={{ padding: "12px 12px 12px 0px" }}
            >
              <Typography className={classes.profileListName}>
                {profile?.ClubName}
              </Typography>
              <Typography className={classes.profileListDescription}>
                {profile?.Locations?.Name}
              </Typography>
            </Box>
            <Box item xs={1}>
              <img
                className={classes.profileListRightArrow}
                src={arrowRight}
                alt="Avatar"
                onError={(e) => {
                  e.target.src = arrowRight;
                }}
              />
            </Box>
          </MenuItem>
        ))}
      </Box>
    </Menu>
  );

  const renderLogoutMenu = () => {
    return (
      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          className: classes.menu,
        }}
        PopoverClasses={{
          paper: classes.popoverPaper,
        }}
      >
        <Box className={classes.dropdownHeader} container component={Grid}>
          <Box
            item
            xs={3}
            border={0}
            component={Grid}
            boxShadow={0}
            className={classes.headerInfo}
          >
            <img
              className={classes.profileAvatar}
              src={profileUri || placeholderIcon}
              alt="Avatar"
              onError={(e) => {
                e.target.src = placeholderIcon;
              }}
            />
          </Box>
          <Box item xs={9} component={Grid} style={{ padding: 12 }}>
            <Typography className={classes.fullName}>
              {basicUserInfo.full_name}
            </Typography>
            <Typography className={classes.email}>
              {basicUserInfo.email}
            </Typography>
          </Box>
          {selectedProfile?.ClubId ? null : (
            <UpgradeText
              subscription={basicUserInfo.subscription}
              onClickUpgrade={onClickUpgrade}
            />
          )}
        </Box>
        <Divider />
        {!currentUser.cc_manager && clubs?.length ? (
          <MenuItem
            className={classes.menuItem}
            onClick={handleProfileDropdownClick}
          >
            <Typography>My Profiles</Typography>
          </MenuItem>
        ) : null}
        <MenuItem className={classes.menuItem} onClick={onLogout}>
          <Typography>Log out</Typography>
        </MenuItem>
        <Box
          item
          xs={9}
          component={Grid}
          style={{ paddingLeft: 14, paddingBottom: 4 }}
        >
          <Typography className={classes.version}>
            {" "}
            Version {VERSION_NUMBER}
          </Typography>
        </Box>
      </Menu>
    );
  };

  const renderSettingsMenu = () => (
    <AccountSettings
      isOpen={showAccountSettings}
      currentUser={basicUserInfo}
      onClose={onCloseAccountSettings}
      profileAvatar={profileUri}
      clubId={selectedProfile?.ClubId}
    />
  );

  return (
    <>
      {renderSettingsIcon()}
      {renderProfileIcon()}
      {renderLogoutMenu()}
      {!currentUser.cc_manager && clubs?.length ? renderMyProfilesMenu() : null}
      {renderSettingsMenu()}
    </>
  );
}