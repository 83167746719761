import { createSlice } from '@reduxjs/toolkit';

const clubConnectSlice = createSlice({
  name: 'clubConnect',
  initialState: {
    selectedProfile: null,
  },
  reducers: {
    saveSelectedProfile: (state, action) => {
      const selectedProfileData = action.payload;
      state.selectedProfile = selectedProfileData;
    },
    clearSelectedProfile: (state) => {
      state.selectedProfile = null;
    },
  },
});

const { actions, reducer } = clubConnectSlice;
export const { saveSelectedProfile, clearSelectedProfile } = actions;
export default reducer;

// *****
// ACTION CREATORS
// *****

export const handleProfileClick = (profile, clubId) => async (dispatch, getState) => {
  let selectedClub = profile;
  if (clubId) {
    const { currentUser } = getState();
    const clubs = currentUser?.club_connect_user
        ? Array.isArray(currentUser?.club_connect_user)
            ? currentUser?.club_connect_user
            : Object.values(currentUser?.club_connect_user)
        : [];
    if (clubs?.length) {
      selectedClub = clubs.find((cl) => cl.ClubId === clubId);
    }
  }
  dispatch(saveSelectedProfile(selectedClub));
};

// New action creator to automatically select the first available club connect profile
export const autoSelectClubConnectProfile = () => async (dispatch, getState) => {
  const { currentUser } = getState();
  
  if (!currentUser?.club_connect_user) {
    return;
  }

  const clubs = Array.isArray(currentUser.club_connect_user)
    ? currentUser.club_connect_user
    : Object.values(currentUser.club_connect_user);

  if (clubs?.length > 0) {
    // If there's only one club, select it automatically
    if (clubs.length === 1) {
      dispatch(saveSelectedProfile(clubs[0]));
    } else {
      // If there are multiple clubs, try to find the last selected one from localStorage
      const lastSelectedClubId = localStorage.getItem('lastSelectedClubId');
      if (lastSelectedClubId) {
        const lastSelectedClub = clubs.find(club => club.ClubId === lastSelectedClubId);
        if (lastSelectedClub) {
          dispatch(saveSelectedProfile(lastSelectedClub));
        }
      }
    }
  }
};

// Modified handleProfileClick to save selection to localStorage
export const handleProfileClickWithPersistence = (profile, clubId) => async (dispatch, getState) => {
  await dispatch(handleProfileClick(profile, clubId));
  
  // Save the selected club ID to localStorage
  if (profile?.ClubId) {
    localStorage.setItem('lastSelectedClubId', profile.ClubId);
  }
};
